import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalService } from '../../shared/services/modal.service';

@Component({
  selector: 'app-small-modal',
  template:
        `<div class="app-small-modal">
            <div class="app-small-modal-body">
                <ng-content></ng-content>
            </div>
        </div>
        <div class="app-small-modal-background"></div>`
})
export class SmallModalComponent implements OnInit, OnDestroy {
  @Input() id: string;
  private element: any;
  constructor(private modalService: ModalService, private el: ElementRef) {
      this.element = el.nativeElement;
  }
  ngOnInit(): void {
      const modal = this;
      // ensure id attribute exists
      if (!this.id) {
          console.error('modal must have an id');
          return;
      }
      // move element to bottom of page (just before </body>) so it can be displayed above everything else
      document.body.appendChild(this.element);
      // close modal on background click
      this.element.addEventListener('click', (e: any) => {
          if (e.target.className === 'app-modal') {
              modal.close();
          }
      });
      // add self (this modal instance) to the modal service so it's accessible from controllers
      this.modalService.add(this);
  }
  // remove self from modal service when component is destroyed
  ngOnDestroy(): void {
      this.modalService.remove(this.id);
      this.element.remove();
  }
  // open modal
  open(): void {
      this.element.style.display = 'block';
      document.body.classList.add('app-modal-open');
  }
  // close modal
  close(): void {
      this.element.style.display = 'none';
      document.body.classList.remove('app-modal-open');
  }
}

