import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss'],
})
export class PdfViewerComponent implements OnInit {
  // id: any;
  // src: any;
  dt: any;
  isSingleSymptom = true;
  isSympt: any;
  pain: any;
  painlocation = '';
  mediciness = '';
  mediciflag = false;
  med: any;
  constructor() {}

  ngOnInit() {
    this.isSympt = localStorage.getItem('isSym');
    if (this.isSympt === 'true' || this.isSympt === null) {
      this.isSingleSymptom = true;
      this.dt = JSON.parse(sessionStorage.getItem('setDataItem'));
      console.log(this.dt);
      // this.dt.symptoms.bodyLocationList.split();
      // console.log(this.dt);
      if (this.dt.symptoms.bodyLocationList !== undefined) {
        this.pain = JSON.parse(this.dt.symptoms.bodyLocationList);
        if (this.pain.length > 0) {
          this.pain.forEach((val, key) => {
            if (key > 0) {
              this.painlocation = this.painlocation + ', ' + val;
            } else {
              this.painlocation = this.painlocation + val;
            }
          });
        } else {

        }
      } else {

      }
      if (this.dt.symptoms.medicines !== undefined) {
        this.mediciflag = true;
        this.med = JSON.parse(this.dt.symptoms.medicines);
        if (this.med.length > 0) {
          this.med.forEach((mval, mkey) => {
            if (mkey > 0) {
              this.mediciness = this.mediciness + ', ' + mval;
            } else {
              this.mediciness = this.mediciness + mval;
            }
          });
        } else {

        }
      } else {
        this.mediciflag = false;
      }
    } else {
      this.isSingleSymptom = false;
      this.dt = JSON.parse(sessionStorage.getItem('setDataItem1'));
      console.log(this.dt);
      if (this.dt.symptoms.length > 0) {
          this.dt.symptoms.forEach((val, key) => {
            if (val.bodyLocationList !== undefined) {
              this.pain = JSON.parse(val.bodyLocationList);
              if (this.pain.length > 0) {
                val.painlocation = '';
                this.pain.forEach((vals, keys) => {
                  if (keys > 0) {
                    val.painlocation = val.painlocation + ', ' + vals;
                  } else {
                    val.painlocation = '';
                    val.painlocation = val.painlocation + vals;
                  }
                });
              } else {
              }
            } else {
            }
            if (val.medicines !== undefined) {
              this.mediciflag = true;
              this.med = JSON.parse(val.medicines);
              if (this.med.length > 0) {
                this.med.forEach((mval, mkey) => {
                  if (mkey > 0) {
                    val.mediciness = val.mediciness + ', ' + mval;
                  } else {
                    val.mediciness = '';
                    val.mediciness = val.mediciness + mval;
                  }
                });
              } else {
              }
            } else {
              this.mediciflag = false;
            }
          });
      } else {

      }
    }
    //   this.router.params.subscribe((params) => (this.id = params['id']));
    //   if (this.id === 'one') {
    //     this.src = '/assets/SymtomRecords/record1.pdf';
    //   } else {
    //     this.src = '/assets/SymtomRecords/record.pdf';
    //   }
  }
  viewPdf() {
    // var chart = new Highcharts();
    // chart.setTitle(null, { text: ' ' });
    // chart.print();
    window.print();
  }
}
