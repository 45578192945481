import {
  Component,
  OnInit,
  OnDestroy,
  ViewRef,
  ChangeDetectorRef,
} from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import * as firebase from 'firebase/app';
import { DatalistService } from '../../shared/services/datalist.service';
import { Subject } from 'rxjs';
import { ModalService } from '../../shared/services/modal.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from '../../../environments/environment';
import {
  FormGroup,
  FormArray,
  FormBuilder,
  Validators,
  FormControl,
} from '@angular/forms';
import { env } from 'process';
import { AppConfigGroupsConstant } from '../../common/constant';
import { flatMap } from 'rxjs/operators';

export const snapshotToArray = (snapshot: any) => {
  const returnArr = [];

  snapshot.forEach((childSnapshot: any) => {
    const item = childSnapshot.val();
    item.key = childSnapshot.key;
    returnArr.push(item);
  });

  return returnArr;
};

@Component({
  selector: 'app-topheader',
  templateUrl: './topheader.component.html',
  styleUrls: ['./topheader.component.scss'],
})
export class TopheaderComponent implements OnInit, OnDestroy {
  enConstant = AppConfigGroupsConstant;
  private ngUnsubscribe: Subject<void> = new Subject();
  roleType: any = sessionStorage.getItem('claimRoleType');
  displayName: string;
  displayImage: string;
  test = 'vvvv';
  name = false;
  sub: any;
  patId: any;
  newApi = false;
  countryCode = '+91';
  isMobile = false;
  isAge = false;
  codeForm: FormGroup;
  helperForm: FormGroup;
  isMale = true;
  isFemale = false;
  autoId: any;
  InviteForm: FormGroup;
  docId: any;
  success = false;
  isMobileAlt = false;
  uid: string;
  checked = false;
  isAgreed = true;
  isChecked = true;
  isSuperAdmin: boolean;
  genderArray: any = [];
  defaultCountry: any;
  defaultWeight: any;
  isham = false;
  basicData = true;
  addData = false;
  isEditprofile = false;
  dsp = false;
  allChats = [];
  allChatcount: any;
  cCode: any = sessionStorage.getItem('uid');
  ptArray = [];
  ptArray1 = '';
  ptArray2 = [];
  chats = [];
  patDetails: any;
  patientdetails = [];
  chatcount: any;
  scode: any;
  patArr = [];
  read: any;
  count = 0;
  cpId: any;
  patsId: any;
  isName = true;
  // isGender = true;
  isGen = true;
  isAg = true;
  isPatId = true;
  isEmail = true;
  isMobil = true;
  isAltMobile = true;
  isHeight = true;
  isWeight = true;
  isbg = true;
  isRefby = true;
  isLang = true;
  isAnumber = true;
  isPatsId = true;
  showMobile = true;
  constructor(
    private cdRef: ChangeDetectorRef,
    public hipaiAuth: AngularFireAuth,
    private fb: FormBuilder,
    private dynamicModalService: ModalService,
    private detservice: DatalistService,
    private router: Router,
    private spinnerService: NgxSpinnerService
  ) {
    // Below code is to set the Name and image of careprovider. Any changes happened in profile screen.
    this.name = true;
    this.displayName = this.detservice.getName();
    this.displayImage = this.detservice.getImage();
    this.detservice.aClickedEventImg.subscribe((data: string) => {
      console.log('Event message from Component A: ' + data);
      this.displayImage = this.detservice.getImage();
      this.displayName = this.detservice.getName();
      this.displayName = sessionStorage.getItem('uname');
    });
    this.detservice.bClickedEvent.subscribe((data: string) => {
      console.log('Event message from Component A: ' + data);
      // this.displayImage = this.detservice.getImage();
      this.invitept();
    });
    this.detservice.cClickedEvent.subscribe((data: string) => {
      console.log('Event message from Component C: ' + data);
      // this.displayImage = this.detservice.getImage();
      this.editprofile(data);
    });
    this.detservice.cMobClickedEvent.subscribe((data: string) => {
      console.log('Event message from Component C: ' + data);
      // this.displayImage = this.detservice.getImage();
      this.editprofileMob(data);
    });
    // Below code for to reduce the topheader section.
    this.detservice.hambEvent.subscribe((data) => {
      console.log('Event message from Component A: ' + data);
      this.isham = data;
    });

    // this.ptArray1 = sessionStorage.getItem('ptArray');
    // this.ptArray2 = JSON.parse(this.ptArray1);

    firebase.default
      .database()
      .ref('chat_count/')
      .child(this.cCode)
      .on('value', (resp) => {
        this.patArr = [];
        this.patArr = snapshotToArray(resp);
        console.log('RES:' + JSON.stringify(this.patArr));
        this.patArr.forEach((val, key) => {
          console.log(val.key);
          this.ptArray2.forEach((pval, pky) => {
            console.log(pval);
            if (pval.guid === val.key) {
              pval.read = val.read;
              console.log(pval.read);
              if (pval.read === 'false') {
                this.count++;
              }
            }
          });
          console.log(this.count);
        });

        if (this.cdRef && !(this.cdRef as ViewRef).destroyed) {
          this.cdRef.detectChanges();
        }

        // if (this.ref && !(this.ref as ViewRef).destroyed) {
        //         this.ref.detectChanges();
        //         this.getPatientList();
        //       }
      });

    // firebase.default
    //   .database()
    //   .ref('chats/')
    //   .on('value', (resp) => {
    //     this.allChats = [];
    //     this.allChats = snapshotToArray(resp);
    //     if (this.allChats.length > 0) {
    //       this.allChatcount = 0;
    //       this.allChats.forEach((val, key) => {
    //         if (val.receiver === this.cCode) {
    //           if (val.read === 'false') {
    //             this.allChatcount++;
    //           } else {
    //           }
    //           setInterval(() => {
    //             if (this.cdRef && !(this.cdRef as ViewRef).destroyed) {
    //               this.cdRef.detectChanges();
    //             }
    //             // this.readmsgcount();
    //           }, 1000);
    //         }
    //       });
    //     }
    //   });
  }
  ngOnInit() {
    this.scode = sessionStorage.getItem('uid');
    this.ptArray1 = sessionStorage.getItem('ptArray');
    this.ptArray2 = JSON.parse(this.ptArray1);
    // Below code is for maintain the country code for healthmeasure weight section.
    if (
      environment.firebase.authDomain === 'hipai-app-demo.firebaseapp.com' ||
      environment.firebase.authDomain === 'hipai-mobile-app.firebaseapp.com'
    ) {
      this.defaultCountry = 'us';
    } else {
      this.defaultCountry = 'in';
    }

    if (
      environment.firebase.authDomain === 'hipai-app-demo.firebaseapp.com' ||
      environment.firebase.authDomain === 'hipai-mobile-app.firebaseapp.com'
    ) {
      this.defaultWeight = 'LBS';
    } else {
      this.defaultWeight = 'KG';
    }
    // this.genderArray = ['Male', 'Female'];
    this.isSuperAdmin = JSON.parse(sessionStorage.getItem('superadminType'));
    if (this.isSuperAdmin) {
    } else {
      this.getChatCount();
      this.getPatientList();
    }
    // alert(this.isSuperAdmin);
    this.displayName = sessionStorage.getItem('uname');
    this.displayImage = sessionStorage.getItem('image');
    console.log('onint');
    this.uid = sessionStorage.getItem('uid');
    // Below code is to createform for careprovider.
    this.codeForm = this.fb.group({
      name: ['', Validators.required],
      // imageURL: ['', Validators.required],
      // Location: ['', Validators.required],
      number: ['', Validators.required],
      about: ['', Validators.required],
      email: [
        '',
        [
          Validators.required,
          Validators.email,
          Validators.pattern(
            '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$'
          ),
        ],
      ],
    });
    // Below code is to create form for invitePatient.
    this.InviteForm = this.fb.group({
      patientName: ['', Validators.required],
      age: ['', Validators.required],
      patientId: ['', Validators.required],
      gender: [null, Validators.required],
      patientNumber: ['', Validators.required],
      patientEmail: [''],
      alternateMobileNumber: [''],
      height: [''],
      weight: [''],
      bloodgroup: [''],
      language: ['en'],
      referby: [''],
      address: [''],
    });
    this.InviteForm.controls['language'].setValue('en');
    this.helperForm = this.fb.group({
      name: ['', Validators.required],
      number: ['', Validators.required],
      email: [
        '',
        [
          Validators.required,
          Validators.email,
          Validators.pattern(
            '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$'
          ),
        ],
      ],
    });
  }
  getChatCount() {
    firebase.default
      .database()
      .ref('chats/')
      .on('value', (resp) => {
        this.chats = [];
        this.chats = snapshotToArray(resp);
      });
  }
  navigate() {
    this.patsId = sessionStorage.getItem('patetId');
    console.log(this.patsId);
    if (this.patsId) {
      this.router.navigate(['/system/web-chat/chat', this.patsId]);
    } else {
      console.log('No Patients found');
    }
  }

  inviteHelper(data) {
    this.helperForm.controls['name'].markAsTouched();
    this.helperForm.controls['email'].markAsTouched();
    this.helperForm.controls['number'].markAsTouched();
    if (
      this.helperForm.controls['name'].invalid ||
      this.helperForm.controls['email'].invalid ||
      this.helperForm.controls['number'].invalid
    ) {
      return;
    }
    this.hideModal('custom-modal-102');
  }
  async getPatientList() {
    this.patientdetails = await this.detservice.getPatientofCareprovider(
      this.scode
    );
    console.log(this.patientdetails.length);
    if (this.patientdetails.length > 0) {
      this.patientdetails.forEach((val, key) => {
        this.patDetails = val.subscriptions[0];
        this.chatcount = 0;
        for (const i of this.chats) {
          if (this.scode === i.receiver) {
            if (i.sender === val.guid) {
              if (i.read === 'false') {
                this.chatcount++;
              } else {
              }
            }
          }
        }
        const obj = {
          name: this.patDetails.patientName,
          gender: this.patDetails.gender,
          age: this.patDetails.age,
          date: this.patDetails.updatedAt,
          ccount: this.chatcount,
          email: this.patDetails.patientEmail,
          mobileNumber: this.patDetails.patientNumber,
          guid: val.guid,
          path: val.path,
        };
        this.ptArray.push(obj);
      });
      sessionStorage.setItem('patetId', this.ptArray[0].guid);
      this.detservice.setPatient(this.ptArray[0]);
      sessionStorage.setItem('pname', this.ptArray[0].name);
      sessionStorage.setItem('patimg', this.ptArray[0].path);
      sessionStorage.setItem('patage', this.ptArray[0].age);
      sessionStorage.setItem('patgender', this.ptArray[0].gender);
      sessionStorage.setItem('patemailId', this.ptArray[0].email);
      sessionStorage.setItem('patmobile', this.ptArray[0].mobileNumber);
      const jsonData = JSON.stringify(this.ptArray);
      sessionStorage.setItem('ptArray', jsonData);
    }

    // this.allChatcount = 0;
    // this.ptArray.forEach((er) => {
    //   this.allChatcount = this.allChatcount + er.ccount;
    // })
  }
  basic() {
    this.basicData = true;
    this.addData = false;
  }

  additional() {
    this.basicData = false;
    this.addData = true;
  }
  // numberOnly(event): boolean {
  //   const charCode = (event.which) ? event.which : event.keyCode;
  //   if (charCode > 31 && (charCode < 48 || charCode > 57)) {
  //     return false;
  //   }
  //   return true;
  // }
  // check() {
  //   this.isAgreed = !this.isAgreed;
  //   this.checked = true;
  // }
  omit_special_char(event) {
    let k;
    k = event.charCode; //         k = event.keyCode;  (Both can be used)
    return (
      (k > 64 && k < 91) ||
      (k > 96 && k < 123) ||
      k === 8 ||
      k === 32 ||
      (k >= 48 && k <= 57)
    );
  }
  // checkBox() {
  //   this.checked = true;
  //   this.isChecked = !this.isChecked;
  // }
  logout() {
    this.detservice.logout();
  }
  public ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
  // To open the popup for creating careprovider.
  careprovider() {
    this.dynamicModalService.open('custom-modal-12');
    this.isChecked = true;
    // this.router.navigate(['/careprovider']);
    // this.showModalLicensee();
  }
  // below code is for select gender and display corresponding icon.
  selecgender(eve) {
    if (eve === 'Male') {
      this.isMale = true;
      this.isFemale = false;
    } else if (eve === 'Female') {
      this.isMale = false;
      this.isFemale = true;
    } else {
      this.isMale = true;
      this.isFemale = false;
    }
  }
  invitept() {
    this.basic();
    this.isEditprofile = false;
    this.checked = false;
    // this.isAgreed = true;
    this.isMobile = false;
    this.dynamicModalService.open('custom-modal-13');
    this.InviteForm = this.fb.group({
      patientName: ['', Validators.required],
      age: ['', Validators.required],
      gender: ['', Validators.required],
      patientNumber: ['', Validators.required],
      patientId: ['', Validators.required],
      patientEmail: [
        '',
        // [
        //   Validators.required,
        //   Validators.email,
        //   Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$'),
        // ],
      ],
      alternateMobileNumber: [''],
      height: [''],
      weight: [''],
      bloodgroup: [''],
      language: ['en'],
      referby: [''],
      address: [''],
    });
  }
  inviteptMob() {
    this.basic();
    this.isEditprofile = false;
    this.checked = false;
    // this.isAgreed = true;
    this.isMobile = false;
    this.dynamicModalService.open('custom-modal-add');
    this.InviteForm = this.fb.group({
      patientName: ['', Validators.required],
      age: ['', Validators.required],
      gender: ['', Validators.required],
      patientNumber: ['', Validators.required],
      patientId: ['', Validators.required],
      patientEmail: [
        '',
        // [
        //   Validators.required,
        //   Validators.email,
        //   Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$'),
        // ],
      ],
      alternateMobileNumber: [''],
      height: [''],
      weight: [''],
      bloodgroup: [''],
      language: ['en'],
      referby: [''],
      address: [''],
    });
  }

  addhelper() {
    this.dynamicModalService.open('custom-modal-102');
  }
  editprofile(data) {
    // console.log('edit prof'+data.profileUpdated[0]);
    if (data.userId === undefined) {
      // New API
      this.newApi = true;
      this.patId = data.id;
    } else {
      this.newApi = false;
      this.patId = data.userId;
      // old API
    }
    this.basic();
    this.isEditprofile = true;
    this.checked = false;
    // this.isAgreed = true;
    this.isMobile = false;
    let gndr;
    this.dynamicModalService.open('custom-modal-13');
    if (data.profileUpdated === undefined) {
      if (data.gender === 'M') {
        gndr = 'Male';
      } else {
        gndr = 'Female';
      }
      this.InviteForm = this.fb.group({
        patientName: [
          data.patientName ? data.patientName : '',
          Validators.required,
        ],
        age: [data.age ? data.age : '', Validators.required],
        gender: [data.gender ? gndr : null, Validators.required],
        patientNumber: [
          data.patientNumber ? data.patientNumber : '',
          Validators.required,
        ],
        patientId: [data.patientId ? data.patientId : '', Validators.required],
        patientEmail: [
          data.patientEmail ? data.patientEmail : '',
          // [
          //   Validators.required,
          //   Validators.email,
          //   Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$'),
          // ],
        ],
        alternateMobileNumber: [
          data.alternateMobileNumber ? data.alternateMobileNumber : '',
        ],
        height: [data.height ? data.height : ''],
        weight: [data.weight ? data.weight : ''],
        bloodgroup: [data.bloodgroup ? data.bloodgroup : null],
        language: [data.language ? data.language : 'en'],
        referby: [data.referby ? data.referby : ''],
        address: [data.address ? data.address : ''],
      });
      if (data.patientNumber.includes('+91')) {
        this.InviteForm.controls.patientNumber.setValue(data.patientNumber);
        // const event = new KeyboardEvent('keyup');
        // this.userInput.nativeElement.dispatchEvent(event);
        this.InviteForm.controls.patientNumber.setValue(
          data.patientNumber.replace('+91', '')
        );
      } else {
        this.InviteForm.controls.patientNumber.setValue(data.patientNumber);
        // const events = new KeyboardEvent('keyup');
        // this.userInput.nativeElement.dispatchEvent(events);
        this.InviteForm.controls.patientNumber.setValue(
          data.patientNumber.replace('+1', '')
        );
      }
    } else {
      this.InviteForm = this.fb.group({
        patientName: [
          data.profileUpdated[0].patientName
            ? data.profileUpdated[0].patientName
            : '',
          Validators.required,
        ],
        age: [
          data.profileUpdated[0].age ? data.profileUpdated[0].age : '',
          Validators.required,
        ],
        gender: [
          data.profileUpdated[0].gender ? data.profileUpdated[0].gender : null,
          Validators.required,
        ],
        patientNumber: [
          data.profileUpdated[0].patientNumber
            ? data.profileUpdated[0].patientNumber
            : '',
          Validators.required,
        ],
        patientId: [
          data.profileUpdated[0].patientId
            ? data.profileUpdated[0].patientId
            : '',
          Validators.required,
        ],
        patientEmail: [
          data.profileUpdated[0].patientEmail
            ? data.profileUpdated[0].patientEmail
            : '',
          // [
          //   Validators.required,
          //   Validators.email,
          //   Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$'),
          // ],
        ],
        alternateMobileNumber: [
          data.profileUpdated[0].alternateMobileNumber
            ? data.profileUpdated[0].alternateMobileNumber
            : '',
        ],
        height: [
          data.profileUpdated[0].height ? data.profileUpdated[0].height : '',
        ],
        weight: [
          data.profileUpdated[0].weight ? data.profileUpdated[0].weight : '',
        ],
        bloodgroup: [
          data.profileUpdated[0].bloodgroup
            ? data.profileUpdated[0].bloodgroup
            : null,
        ],
        language: [
          data.profileUpdated[0].language
            ? data.profileUpdated[0].language
            : 'en',
        ],
        referby: [
          data.profileUpdated[0].referby ? data.profileUpdated[0].referby : '',
        ],
        address: [
          data.profileUpdated[0].address ? data.profileUpdated[0].address : '',
        ],
      });
      if (data.profileUpdated[0].patientNumber.includes('+91')) {
        this.InviteForm.controls.patientNumber.setValue(
          data.profileUpdated[0].patientNumber
        );
        // const event = new KeyboardEvent('keyup');
        // this.userInput.nativeElement.dispatchEvent(event);
        this.InviteForm.controls.patientNumber.setValue(
          data.profileUpdated[0].patientNumber.replace('+91', '')
        );
        if (data.profileUpdated[0].alternateMobileNumber === undefined) {
        } else {
          this.InviteForm.controls.alternateMobileNumber.setValue(
            data.profileUpdated[0].alternateMobileNumber.replace('+91', '')
          );
        }
      } else {
        this.InviteForm.controls.patientNumber.setValue(
          data.profileUpdated[0].patientNumber
        );
        // const events = new KeyboardEvent('keyup');
        // this.userInput.nativeElement.dispatchEvent(events);
        this.InviteForm.controls.patientNumber.setValue(
          data.profileUpdated[0].patientNumber.replace('+1', '')
        );
        if (data.profileUpdated[0].alternateMobileNumber === undefined) {
        } else {
          this.InviteForm.controls.alternateMobileNumber.setValue(
            data.profileUpdated[0].alternateMobileNumber.replace('+1', '')
          );
        }
      }
    }
  }
  editprofileMob(data) {
    if (data.userId === undefined) {
      console.log('IF');
      // New API
      this.newApi = true;
      this.patId = data.id;
    } else {
      this.newApi = false;
      this.patId = data.userId;
      // old API
    }
    this.basic();
    this.isEditprofile = true;
    // this.checked = false;
    // this.isAgreed = true;
    // if(data.profileUpdated[0].patientName === null || data.profileUpdated[0].patientName === undefined) {
    //   this.isName = true;
    // } else {
    //   this.isName = false;
    // }
    this.isName = false;
    this.isGen = false;
    this.isAg = false;
    this.isPatsId = false;
    this.isEmail = false;
    this.isMobil = false;
    this.isAltMobile = false;
    this.isAnumber = false;
    this.showMobile = false;
    // this.isHeight = false;
    // this.isWeight = false;
    // this.isbg = false;

    this.isRefby = false;
    this.isLang = false;
    this.isMobile = false;
    if (data.height === undefined || data.height === '') {
      this.isHeight = true;
    }
    if (data.weight === undefined || data.weight === '') {
      this.isWeight = true;
    }
    if (data.bloodgroup === undefined || data.bloodgroup === '') {
      this.isbg = true;
    }
    if (data.referby === undefined || data.referby === '') {
      this.isRefby = true;
    }
    if (data.language === undefined || data.language === '') {
      this.isLang = true;
    }
    if (
      data.alternateMobileNumber === undefined ||
      data.alternateMobileNumber === ''
    ) {
      this.isAnumber = true;
    } else {
      this.isAnumber = false;
    }
    if (data.patientEmail === undefined || data.patientEmail === '') {
      this.isEmail = true;
    }
    if (data.patientNumber === undefined || data.patientNumber === '') {
      this.isMobil = true;
    }
    this.dynamicModalService.open('custom-modal-add');
    if (data.profileUpdated === undefined) {
      this.InviteForm = this.fb.group({
        patientName: [
          data.patientName ? data.patientName : '',
          Validators.required,
        ],
        age: [data.age ? data.age : '', Validators.required],
        gender: [data.gender ? data.gender : null, Validators.required],
        patientNumber: [
          data.patientNumber ? data.patientNumber : '',
          Validators.required,
        ],
        patientId: [data.patientId ? data.patientId : '', Validators.required],
        patientEmail: [
          data.patientEmail ? data.patientEmail : '',
          // [
          //   Validators.required,
          //   Validators.email,
          //   Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$'),
          // ],
        ],
        alternateMobileNumber: [
          data.alternateMobileNumber ? data.alternateMobileNumber : '',
        ],
        height: [data.height ? data.height : ''],
        weight: [data.weight ? data.weight : ''],
        bloodgroup: [data.bloodgroup ? data.bloodgroup : null],
        language: [data.language ? data.language : 'en'],
        referby: [data.referby ? data.referby : ''],
        address: [data.address ? data.address : ''],
      });
      if (data.patientNumber.includes('+91')) {
        this.InviteForm.controls.patientNumber.setValue(data.patientNumber);
        // const event = new KeyboardEvent('keyup');
        // this.userInput.nativeElement.dispatchEvent(event);
        this.InviteForm.controls.patientNumber.setValue(
          data.patientNumber.replace('+91', '')
        );
      } else {
        this.InviteForm.controls.patientNumber.setValue(data.patientNumber);
        // const events = new KeyboardEvent('keyup');
        // this.userInput.nativeElement.dispatchEvent(events);
        this.InviteForm.controls.patientNumber.setValue(
          data.patientNumber.replace('+1', '')
        );
      }
    } else {
      this.InviteForm = this.fb.group({
        patientName: [
          data.profileUpdated[0].patientName
            ? data.profileUpdated[0].patientName
            : '',
          Validators.required,
        ],
        age: [
          data.profileUpdated[0].age ? data.profileUpdated[0].age : '',
          Validators.required,
        ],
        gender: [
          data.profileUpdated[0].gender ? data.profileUpdated[0].gender : null,
          Validators.required,
        ],
        patientNumber: [
          data.profileUpdated[0].patientNumber
            ? data.profileUpdated[0].patientNumber
            : '',
          Validators.required,
        ],
        patientId: [
          data.profileUpdated[0].patientId
            ? data.profileUpdated[0].patientId
            : '',
          Validators.required,
        ],
        patientEmail: [
          data.profileUpdated[0].patientEmail
            ? data.profileUpdated[0].patientEmail
            : '',
          // [
          //   Validators.required,
          //   Validators.email,
          //   Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$'),
          // ],
        ],
        alternateMobileNumber: [
          data.profileUpdated[0].alternateMobileNumber
            ? data.profileUpdated[0].alternateMobileNumber
            : '',
        ],
        height: [
          data.profileUpdated[0].height ? data.profileUpdated[0].height : '',
        ],
        weight: [
          data.profileUpdated[0].weight ? data.profileUpdated[0].weight : '',
        ],
        bloodgroup: [
          data.profileUpdated[0].bloodgroup
            ? data.profileUpdated[0].bloodgroup
            : null,
        ],
        language: [
          data.profileUpdated[0].language
            ? data.profileUpdated[0].language
            : 'en',
        ],
        referby: [
          data.profileUpdated[0].referby ? data.profileUpdated[0].referby : '',
        ],
        address: [
          data.profileUpdated[0].address ? data.profileUpdated[0].address : '',
        ],
      });
      if (data.profileUpdated[0].patientNumber.includes('+91')) {
        this.InviteForm.controls.patientNumber.setValue(
          data.profileUpdated[0].patientNumber
        );
        // const event = new KeyboardEvent('keyup');
        // this.userInput.nativeElement.dispatchEvent(event);
        this.InviteForm.controls.patientNumber.setValue(
          data.profileUpdated[0].patientNumber.replace('+91', '')
        );
        if (data.profileUpdated[0].alternateMobileNumber === undefined) {
        } else {
          this.InviteForm.controls.alternateMobileNumber.setValue(
            data.profileUpdated[0].alternateMobileNumber.replace('+91', '')
          );
        }
      } else {
        this.InviteForm.controls.patientNumber.setValue(
          data.profileUpdated[0].patientNumber
        );
        // const events = new KeyboardEvent('keyup');
        // this.userInput.nativeElement.dispatchEvent(events);
        this.InviteForm.controls.patientNumber.setValue(
          data.profileUpdated[0].patientNumber.replace('+1', '')
        );
        if (data.profileUpdated[0].alternateMobileNumber === undefined) {
        } else {
          this.InviteForm.controls.alternateMobileNumber.setValue(
            data.profileUpdated[0].alternateMobileNumber.replace('+1', '')
          );
        }
      }
    }
  }
  hideModal(id: string) {
    this.checked = false;
    this.dynamicModalService.close(id);
  }
  onFocusActvity() {
    this.isMobile = false;
  }
  clearText() {
    this.isAge = false;
  }
  autogenerate() {
    this.autoId = this.makeid(8);
    console.log(this.autoId);
    this.InviteForm.controls.patientId.setValue(this.autoId);
  }
  // Below code is to create a careprovider and API integration.
  async onSubmit(data) {
    this.codeForm.controls['name'].markAsTouched();
    // this.codeForm.controls['number'].markAsTouched();
    this.codeForm.controls['email'].markAsTouched();
    this.codeForm.controls['about'].markAsTouched();
    // this.codeForm.controls['password'].markAsTouched();
    // this.codeForm.controls['Location'].markAsTouched();
    // if (data.number.length < 10) {
    //   this.isMobile = true;
    //   return;
    // } else {
    //   this.isMobile = false;
    // }
    if (data.number.includes('(')) {
      if (data.number.includes('+1')) {
        data.number =
          data.number.split(' ')[0] +
          data.number.split(' ')[1] +
          data.number.split(' ')[2];
      } else {
        data.number = '+1' + data.number;
        data.number =
          data.number.split(' ')[0] +
          data.number.split(' ')[1] +
          data.number.split(' ')[2];
      }
      if (data.number.length < 15) {
        this.isMobile = true;
        return;
      } else {
        this.isMobile = false;
      }
    } else {
      data.number = data.number.split(' ')[0] + data.number.split(' ')[1];
      data.number = data.number;
      if (data.number.length < 13) {
        if (data.number.length === 10) {
          data.number = '+91' + data.number;
        } else {
          this.isMobile = true;
          return;
        }
      } else {
        this.isMobile = false;
      }
    }
    // if (this.codeForm.invalid) {
    //   return;
    // }
    if (
      this.codeForm.controls['name'].invalid ||
      this.codeForm.controls['email'].invalid ||
      this.codeForm.controls['about'].invalid
    ) {
      return;
    }
    const today = new Date();
    const date =
      today.getDate() +
      '-' +
      (today.getMonth() + 1) +
      '-' +
      today.getFullYear();

    this.docId = this.makeid(20);
    // data.docId = this.docId;
    data.sauid = this.uid;
    data.status = 'Invited';
    data.name = data.name.toLowerCase();
    data.name = data.name.charAt(0).toUpperCase() + data.name.slice(1);
    data.numberofcareplans = 0;
    data.numberofpatients = 0;
    data.inviteddate = date;
    // data.number = this.countryCode + data.number;
    try {
      // Below code call POST API to create a careprovider.
      this.spinnerService.show();
      const signInResponse = await this.detservice.inviteCareProvidersAPi(data);
      this.success = true;
      // this.codeForm.reset();
      this.spinnerService.hide();
      // this.changeRole();
      // Below code is to show the toast message
      if (signInResponse[0].message) {
        this.codeForm.controls['number'].setValue('');
        this.codeForm.controls['email'].setValue('');
        this.isShowToastMsgforduplicateforcare(signInResponse[0].message);
      } else {
        this.isShowToastMsg('Careprovider Invited Successfully');
        this.hideModal('custom-modal-12');
        this.detservice.AClicked('Component A is clicked!!');
        this.codeForm.reset();
      }
      // this.SendVerificationMail();
      // this.hideModal('custom-modal-12');
    } catch (error) {
      this.isShowToastMsg(this.detservice.getErrors(error));
    } finally {
      // this.spinnerService.hide();
    }
    // this.detservice.inviteCareProviders(data).then((res) => {
    //   this.success = true;
    //   this.codeForm.reset();
    //   // this.SendVerificationMail();
    //   this.hideModal('custom-modal-12');
    // });
  }
  // Below code is to close the modal popup.
  closePopup() {
    this.InviteForm.reset();
    this.hideModal('custom-modal-13');
  }

  closePopupAdd() {
    this.InviteForm.reset();
    this.hideModal('custom-modal-add');
  }
  // SendVerificationMail() {
  //   return this.hipaiAuth.auth.currentUser
  //     .sendEmailVerification()
  //     .then(() => {});
  // }
  // Below code is to create a patient or invite a patient and also make POST API call.
  async invitePatient(data) {
    this.docId = '';
    this.InviteForm.controls['patientName'].markAsTouched();
    // this.InviteForm.controls['patientNumber'].markAsTouched();
    // this.InviteForm.controls['patientEmail'].markAsTouched();
    this.InviteForm.controls['age'].markAsTouched();
    this.InviteForm.controls['gender'].markAsTouched();
    this.InviteForm.controls['patientId'].markAsTouched();
    if (data.age < 18 && data.age !== '') {
      this.isAge = true;
      this.basic();
      return;
    } else {
      this.isAge = false;
    }
    // if (data.patientNumber.length < 10) {
    //   this.isMobile = true;
    //   return;
    // } else {
    //   this.isMobile = false;
    // }
    if (data.patientNumber.includes('(')) {
      if (data.patientNumber.includes('+1')) {
        data.patientNumber =
          data.patientNumber.split(' ')[0] +
          data.patientNumber.split(' ')[1] +
          data.patientNumber.split(' ')[2];
      } else {
        data.patientNumber = '+1' + data.patientNumber;
        if (data.patientNumber.includes(' ')) {
          data.patientNumber =
            data.patientNumber.split(' ')[0] +
            data.patientNumber.split(' ')[1] +
            data.patientNumber.split(' ')[2];
        } else {
        }
      }
      if (data.patientNumber.length < 15) {
        this.isMobile = true;
        this.basic();
        return;
      } else {
        this.isMobile = false;
      }
    } else {
      if (data.patientNumber.length === 10) {
        data.patientNumber = '+91 ' + data.patientNumber;
      }
      if (data.patientNumber.includes(' ')) {
        data.patientNumber =
          data.patientNumber.split(' ')[0] + data.patientNumber.split(' ')[1];
        data.patientNumber = data.patientNumber;
      } else {
      }
      if (data.patientNumber.length < 13) {
        if (data.patientNumber.length === 10) {
          data.patientNumber = '+91' + data.patientNumber;
        } else {
          this.isMobile = true;
          this.basic();
          return;
        }
      } else {
        this.isMobile = false;
      }
    }

    if (
      data.alternateMobileNumber === null ||
      data.alternateMobileNumber === undefined
    ) {
      data.alternateMobileNumber = '';
    } else {
      if (data.alternateMobileNumber.includes('(')) {
        if (data.alternateMobileNumber.includes('+1')) {
          if (data.alternateMobileNumber.includes(' ')) {
            data.alternateMobileNumber =
              data.alternateMobileNumber.split(' ')[0] +
              data.alternateMobileNumber.split(' ')[1] +
              data.alternateMobileNumber.split(' ')[2];
          }
        } else {
          data.alternateMobileNumber = '+1' + data.alternateMobileNumber;
          if (data.alternateMobileNumber.includes(' ')) {
            data.alternateMobileNumber =
              data.alternateMobileNumber.split(' ')[0] +
              data.alternateMobileNumber.split(' ')[1] +
              data.alternateMobileNumber.split(' ')[2];
          } else {
          }
        }
        if (data.alternateMobileNumber.length < 15) {
          this.isMobileAlt = true;
          return;
        } else {
          this.isMobileAlt = false;
        }
      } else {
        if (data.alternateMobileNumber.length === 10) {
          data.alternateMobileNumber = '+91 ' + data.patientNumber;
        } else if (data.alternateMobileNumber === '+91 ') {
          data.alternateMobileNumber = '';
        } else {
        }
        if (data.alternateMobileNumber !== '') {
          if (data.alternateMobileNumber.includes(' ')) {
            data.alternateMobileNumber =
              data.alternateMobileNumber.split(' ')[0] +
              data.alternateMobileNumber.split(' ')[1];
            data.alternateMobileNumber = data.alternateMobileNumber;
          } else {
          }
          if (data.alternateMobileNumber.length < 13) {
            if (data.alternateMobileNumber.length === 10) {
              data.alternateMobileNumber = '+91' + data.alternateMobileNumber;
            } else {
              this.isMobileAlt = true;
              this.basic();
              return;
            }
          } else {
            this.isMobileAlt = false;
          }
        } else {
        }
      }
    }
    // if (this.InviteForm.invalid) {
    //   return;
    // }
    if (
      this.InviteForm.controls['patientName'].invalid ||
      this.InviteForm.controls['age'].invalid ||
      this.InviteForm.controls['gender'].invalid ||
      this.InviteForm.controls['patientId'].invalid
    ) {
      this.basic();
      return;
    }

    if (
      data.height === '' &&
      data.weight === '' &&
      data.bloodgroup === '' &&
      data.referby === '' &&
      data.address === ''
    ) {
      delete data.height;
      delete data.weight;
      delete data.bloodgroup;
      delete data.address;
      delete data.referby;
    } else {
    }
    // this.docId = this.makeid(20);
    // data.docId = this.docId;
    data.cpuid = this.uid;
    data.status = 'Invited';
    data.patientName = data.patientName.toLowerCase();
    data.patientId = data.patientId.toUpperCase();
    data.patientName =
      data.patientName.charAt(0).toUpperCase() + data.patientName.slice(1);
    // data.patientNumber = this.countryCode + data.patientNumber;
    data.age = data.age.toString();
    const today = new Date();
    const date =
      today.getDate() +
      '-' +
      (today.getMonth() + 1) +
      '-' +
      today.getFullYear();
    data.invitedDate = date;
    // this.detservice.invitePatients(data).then((res) => {
    //   this.success = true;
    //   this.InviteForm.reset();
    //   this.hideModal('custom-modal-13');
    //   this.router.navigate(['/invitedPatients']);
    // });
    // Below code is API integration for invite a patient.
    if (this.isEditprofile && this.newApi) {
      delete data.status;
      try {
        this.spinnerService.show();
        const signInResponse = await this.detservice.updatePatientprofilenosuserid(
          this.patId,
          data
        );
        if (signInResponse[0].message === 'Ok') {
          this.hideModal('custom-modal-13');
          this.detservice.AClicked('Component A is clicked!!');
        } else {
          this.isShowToastMsg(signInResponse[0].message);
        }
      } catch (error) {
        this.spinnerService.hide();
        this.hideModal('custom-modal-13');
        this.isShowToastMsg(this.detservice.getErrors(error));
      } finally {
        this.spinnerService.hide();
      }
    } else if (this.isEditprofile && !this.newApi) {
      delete data.status;
      try {
        this.spinnerService.show();
        const signInResponse = await this.detservice.updatePatientprofile(
          this.patId,
          data
        );
        if (signInResponse[0].message === 'Ok') {
          this.hideModal('custom-modal-13');
          this.detservice.AClicked('Component A is clicked!!');
        } else {
          this.isShowToastMsg(signInResponse[0].message);
        }
      } catch (error) {
        this.spinnerService.hide();
        this.hideModal('custom-modal-13');
        this.isShowToastMsg(this.detservice.getErrors(error));
      } finally {
        this.spinnerService.hide();
      }
    } else {
      try {
        delete data.alternateMobileNumber;
        this.spinnerService.show();
        const signInResponse = await this.detservice.invitePateint(data);
        this.detservice.logEvent('Invite_Patient', { name: data.patientName });
        this.success = true;
        this.spinnerService.hide();
        // Below code is to show the toast message based on api response.
        if (signInResponse[0].message) {
          this.InviteForm.controls['patientEmail'].setValue('');
          this.InviteForm.controls['patientNumber'].setValue('');
          this.isShowToastMsgforduplicate(signInResponse[0].message);
        } else {
          this.isShowToastMsg('Patient Invited Successfully');
          this.hideModal('custom-modal-13');
          this.detservice.AClicked('Component A is clicked!!');
          this.InviteForm.reset();
        }
        // this.router.navigate(['/invitedPatients']);
      } catch (error) {
        this.spinnerService.hide();
        this.isShowToastMsg(this.detservice.getErrors(error));
      } finally {
        this.spinnerService.hide();
      }
    }
  }
  async invitePatientMob(data) {
    this.docId = '';
    this.InviteForm.controls['patientName'].markAsTouched();
    // this.InviteForm.controls['patientNumber'].markAsTouched();
    // this.InviteForm.controls['patientEmail'].markAsTouched();
    this.InviteForm.controls['age'].markAsTouched();
    this.InviteForm.controls['gender'].markAsTouched();
    this.InviteForm.controls['patientId'].markAsTouched();
    if (data.age < 18 && data.age !== '') {
      this.isAge = true;
      this.basic();
      return;
    } else {
      this.isAge = false;
    }
    // if (data.patientNumber.length < 10) {
    //   this.isMobile = true;
    //   return;
    // } else {
    //   this.isMobile = false;
    // }
    console.clear();
    console.log(data.patientNumber);
    if (data.patientNumber.includes('(')) {
      console.log('if' + this.isMobile);
      if (data.patientNumber.includes('+1')) {
        data.patientNumber =
          data.patientNumber.split(' ')[0] +
          data.patientNumber.split(' ')[1] +
          data.patientNumber.split(' ')[2];
      } else {
        data.patientNumber = '+1' + data.patientNumber;
        if (data.patientNumber.includes(' ')) {
          data.patientNumber =
            data.patientNumber.split(' ')[0] +
            data.patientNumber.split(' ')[1] +
            data.patientNumber.split(' ')[2];
        } else {
        }
      }
      if (data.patientNumber.length < 15) {
        this.isMobile = true;
        this.basic();
        return;
      } else {
        this.isMobile = false;
      }
    } else {
      console.log('else' + this.isMobile);
      if (data.patientNumber.length === 10) {
        console.log('1IF');
        data.patientNumber = '+91 ' + data.patientNumber;
      }
      if (data.patientNumber.includes(' ')) {
        console.log('2IF');
        data.patientNumber =
          data.patientNumber.split(' ')[0] + data.patientNumber.split(' ')[1];
        data.patientNumber = data.patientNumber;
      } else {
      }
      if (data.patientNumber.length < 13) {
        console.log('3IF');
        if (data.patientNumber.length === 10) {
          data.patientNumber = '+91' + data.patientNumber;
        } else {
          this.isMobile = true;
          this.basic();
          return;
        }
      } else {
        this.isMobile = false;
      }
    }
    console.log('OUT' + this.isMobile);
    if (
      data.alternateMobileNumber === null ||
      data.alternateMobileNumber === undefined
    ) {
      data.alternateMobileNumber = '';
    } else {
      if (data.alternateMobileNumber.includes('(')) {
        if (data.alternateMobileNumber.includes('+1')) {
          if (data.alternateMobileNumber.includes(' ')) {
            data.alternateMobileNumber =
              data.alternateMobileNumber.split(' ')[0] +
              data.alternateMobileNumber.split(' ')[1] +
              data.alternateMobileNumber.split(' ')[2];
          }
        } else {
          data.alternateMobileNumber = '+1' + data.alternateMobileNumber;
          if (data.alternateMobileNumber.includes(' ')) {
            data.alternateMobileNumber =
              data.alternateMobileNumber.split(' ')[0] +
              data.alternateMobileNumber.split(' ')[1] +
              data.alternateMobileNumber.split(' ')[2];
          } else {
          }
        }
        if (data.alternateMobileNumber.length < 15) {
          this.isMobileAlt = true;
          return;
        } else {
          this.isMobileAlt = false;
        }
      } else {
        if (data.alternateMobileNumber.length === 10) {
          data.alternateMobileNumber = '+91 ' + data.patientNumber;
        } else if (data.alternateMobileNumber === '+91 ') {
          data.alternateMobileNumber = '';
        } else {
        }
        if (data.alternateMobileNumber !== '') {
          if (data.alternateMobileNumber.includes(' ')) {
            data.alternateMobileNumber =
              data.alternateMobileNumber.split(' ')[0] +
              data.alternateMobileNumber.split(' ')[1];
            data.alternateMobileNumber = data.alternateMobileNumber;
          } else {
          }
          if (data.alternateMobileNumber.length < 13) {
            if (data.alternateMobileNumber.length === 10) {
              data.alternateMobileNumber = '+91' + data.alternateMobileNumber;
            } else {
              this.isMobileAlt = true;
              this.basic();
              return;
            }
          } else {
            this.isMobileAlt = false;
          }
        } else {
        }
      }
    }
    // if (this.InviteForm.invalid) {
    //   return;
    // }
    if (
      this.InviteForm.controls['patientName'].invalid ||
      this.InviteForm.controls['age'].invalid ||
      this.InviteForm.controls['gender'].invalid ||
      this.InviteForm.controls['patientId'].invalid
    ) {
      this.basic();
      return;
    }

    if (
      data.height === '' &&
      data.weight === '' &&
      data.bloodgroup === '' &&
      data.referby === '' &&
      data.address === ''
    ) {
      delete data.height;
      delete data.weight;
      delete data.bloodgroup;
      delete data.address;
      delete data.referby;
    } else {
    }
    // this.docId = this.makeid(20);
    // data.docId = this.docId;
    data.cpuid = this.uid;
    data.status = 'Invited';
    data.patientName = data.patientName.toLowerCase();
    data.patientId = data.patientId.toUpperCase();
    data.patientName =
      data.patientName.charAt(0).toUpperCase() + data.patientName.slice(1);
    // data.patientNumber = this.countryCode + data.patientNumber;
    data.age = data.age.toString();
    const today = new Date();
    const date =
      today.getDate() +
      '-' +
      (today.getMonth() + 1) +
      '-' +
      today.getFullYear();
    data.invitedDate = date;
    // this.detservice.invitePatients(data).then((res) => {
    //   this.success = true;
    //   this.InviteForm.reset();
    //   this.hideModal('custom-modal-13');
    //   this.router.navigate(['/invitedPatients']);
    // });
    // Below code is API integration for invite a patient.
    if (this.isEditprofile && this.newApi) {
      delete data.status;
      try {
        this.spinnerService.show();
        const signInResponse = await this.detservice.updatePatientprofilenosuserid(
          this.patId,
          data
        );
        if (signInResponse[0].message === 'Ok') {
          this.hideModal('custom-modal-add');
          this.detservice.AClicked('Component A is clicked!!');
        } else {
          this.isShowToastMsg(signInResponse[0].message);
        }
      } catch (error) {
        this.spinnerService.hide();
        this.hideModal('custom-modal-add');
        this.isShowToastMsg(this.detservice.getErrors(error));
      } finally {
        this.spinnerService.hide();
      }
    } else if (this.isEditprofile && !this.newApi) {
      delete data.status;
      try {
        this.spinnerService.show();
        const signInResponse = await this.detservice.updatePatientprofile(
          this.patId,
          data
        );
        if (signInResponse[0].message === 'Ok') {
          this.hideModal('custom-modal-add');
          this.detservice.AClicked('Component A is clicked!!');
        } else {
          this.isShowToastMsg(signInResponse[0].message);
        }
      } catch (error) {
        this.spinnerService.hide();
        this.hideModal('custom-modal-add');
        this.isShowToastMsg(this.detservice.getErrors(error));
      } finally {
        this.spinnerService.hide();
      }
    } else {
      try {
        delete data.alternateMobileNumber;
        this.spinnerService.show();
        const signInResponse = await this.detservice.invitePateint(data);
        this.detservice.logEvent('Invite_Patient', { name: data.patientName });
        this.success = true;
        this.spinnerService.hide();
        // Below code is to show the toast message based on api response.
        if (signInResponse[0].message) {
          this.InviteForm.controls['patientEmail'].setValue('');
          this.InviteForm.controls['patientNumber'].setValue('');
          this.isShowToastMsgforduplicate(signInResponse[0].message);
        } else {
          this.isShowToastMsg('Patient Invited Successfully');
          this.hideModal('custom-modal-add');
          this.detservice.AClicked('Component A is clicked!!');
          this.InviteForm.reset();
        }
        // this.router.navigate(['/invitedPatients']);
      } catch (error) {
        this.spinnerService.hide();
        this.isShowToastMsg(this.detservice.getErrors(error));
      } finally {
        this.spinnerService.hide();
      }
    }
  }
  // Below functions for displaying toast msgs.
  isShowToastMsg(msg) {
    const x = document.getElementById('snackbar');
    x.className = 'show';
    x.innerHTML = msg;
    setTimeout(() => {
      x.className = x.className.replace('show', '');
    }, 3000);
  }

  isShowToastMsgforduplicate(msg) {
    const x = document.getElementById('snackbaroldpat');
    x.className = 'shows';
    x.innerHTML = msg;
    setTimeout(() => {
      x.className = x.className.replace('shows', '');
    }, 12000);
  }
  isShowToastMsgforduplicateforcare(msg) {
    const x = document.getElementById('snackbarold');
    x.className = 'shows';
    x.innerHTML = msg;
    setTimeout(() => {
      x.className = x.className.replace('shows', '');
    }, 12000);
  }
  makeid(length) {
    let result = '';
    const characters = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  telInputObject(ev) {
    console.log(ev);
  }

  onCountryChange(e) {
    this.countryCode = '+' + e.dialCode;
  }

  getNumber(e) {
    console.log(e);
  }

  onError(e) {}
  // below code is to redirect to profile screen.
  gotoProfile() {
    this.detservice.getUserdetailsByid(this.uid).subscribe((res) => {
      this.detservice.setProfiledata(res[0]);
      this.router.navigate(['/system/settings/profile']);
    });
  }

  selectdata(data) {
    if (data !== null) {
      this.isName = false;
      // this.isGender = false;
      // this.isAg = false;
      // this.isPatId = false;
      // this.isEmail = false;
      // this.isMobil = false;
      // this.isAltMobile = false;
      // this.isHeight = false;
      // this.isWeight = false;
      // this.isbg = false;
      // this.isRefby = false;
      // this.isLang = false
    }
  }
  selectdata1(data) {
    if (data !== null) {
      // this.isName = false;
      this.isGen = false;
      // this.isAg = false;
      // this.isPatId = false;
      // this.isEmail = false;
      // this.isMobil = false;
      // this.isAltMobile = false;
      // this.isHeight = false;
      // this.isWeight = false;
      // this.isbg = false;
      // this.isRefby = false;
      // this.isLang = false
    }
  }
  selectdata2(data) {
    if (data !== null) {
      // this.isName = false;
      // this.isGender = false;
      this.isAg = false;
      // this.isPatId = false;
      // this.isEmail = false;
      // this.isMobil = false;
      // this.isAltMobile = false;
      // this.isHeight = false;
      // this.isWeight = false;
      // this.isbg = false;
      // this.isRefby = false;
      // this.isLang = false
    }
  }
  selectdata4(data) {
    if (data !== null) {
      // this.isName = false;
      // this.isGender = false;
      // this.isAg = false;
      // this.isPatId = false;
      this.isEmail = false;
      // this.isMobil = false;
      // this.isAltMobile = false;
      // this.isHeight = false;
      // this.isWeight = false;
      // this.isbg = false;
      // this.isRefby = false;
      // this.isLang = false
    }
  }
  selectdata5(data) {
    if (data !== null) {
      // this.isName = false;
      // this.isGender = false;
      // this.isAg = false;
      // this.isPatId = false;
      // this.isEmail = false;
      this.isMobil = false;
      // this.isAltMobile = false;
      // this.isHeight = false;
      // this.isWeight = false;
      // this.isbg = false;
      // this.isRefby = false;
      // this.isLang = false
    }
  }
  selectdata6(data) {
    if (data !== null) {
      // this.isName = false;
      // this.isGender = false;
      // this.isAg = false;
      // this.isPatId = false;
      // this.isEmail = false;
      // this.isMobil = false;
      this.isAltMobile = false;
      // this.isHeight = false;
      // this.isWeight = false;
      // this.isbg = false;
      // this.isRefby = false;
      // this.isLang = false
    }
  }
  selectdata7(data) {
    if (data !== null) {
      // this.isName = false;
      // this.isGender = false;
      // this.isAg = false;
      // this.isPatId = false;
      // this.isEmail = false;
      // this.isMobil = false;
      // this.isAltMobile = false;
      this.isHeight = false;
      // this.isWeight = false;
      // this.isbg = false;
      // this.isRefby = false;
      // this.isLang = false
    }
  }
  selectdata8(data) {
    if (data !== null) {
      // this.isName = false;
      // this.isGender = false;
      // this.isAg = false;
      // this.isPatId = false;
      // this.isEmail = false;
      // this.isMobil = false;
      // this.isAltMobile = false;
      // this.isHeight = false;
      this.isWeight = false;
      // this.isbg = false;
      // this.isRefby = false;
      // this.isLang = false
    }
  }
  selectdata9(data) {
    if (data !== null) {
      // this.isName = false;
      // this.isGender = false;
      // this.isAg = false;
      // this.isPatId = false;
      // this.isEmail = false;
      // this.isMobil = false;
      // this.isAltMobile = false;
      // this.isHeight = false;
      // this.isWeight = false;
      this.isbg = false;
      // this.isRefby = false;
      // this.isLang = false
    }
  }
  selectdata10(data) {
    if (data !== null) {
      // this.isName = false;
      // this.isGender = false;
      // this.isAg = false;
      // this.isPatId = false;
      // this.isEmail = false;
      // this.isMobil = false;
      // this.isAltMobile = false;
      // this.isHeight = false;
      // this.isWeight = false;
      // this.isbg = false;
      this.isRefby = false;
      // this.isLang = false
    }
  }
  selectdata11(data) {
    if (data !== null) {
      // this.isName = false;
      // this.isGender = false;
      // this.isAg = false;
      // this.isPatId = false;
      // this.isEmail = false;
      // this.isMobil = false;
      // this.isAltMobile = false;
      // this.isHeight = false;
      // this.isWeight = false;
      // this.isbg = false;
      // this.isRefby = false;
      this.isLang = false;
    }
  }
}
