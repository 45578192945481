// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API_URL: 'https://hipai-gateway-3phr2r75nq-uc.a.run.app/hipai/v1/',
  apiKey: 'AIzaSyA6n3cEc0_7pO_nvNdLh9NIk6t_SqkFmhE',
  databaseURL: 'https://hipai-mobile-test.firebaseio.com/',
  AccessToken: '12345',
  firebase: {
    apiKey: 'AIzaSyA6n3cEc0_7pO_nvNdLh9NIk6t_SqkFmhE',
    authDomain: 'hipai-mobile-test.firebaseapp.com',
    databaseURL: 'https://hipai-mobile-test.firebaseio.com',
    projectId: 'hipai-mobile-test',
    storageBucket: 'hipai-mobile-test.appspot.com',
    messagingSenderId: '16788164283',
    appId: '1:16788164283:web:89c9422b44944ba1',
    measurementId: 'G-053RJJ99TR'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
