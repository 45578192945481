import { Component, OnInit, ChangeDetectorRef, ViewRef } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import * as firebase from 'firebase/app';
import { DatalistService } from '../../shared/services/datalist.service';
import { NgxSpinnerService } from 'ngx-spinner';
export const snapshotToArray = (snapshot: any) => {
  const returnArr = [];

  snapshot.forEach((childSnapshot: any) => {
    const item = childSnapshot.val();
    item.key = childSnapshot.key;
    returnArr.push(item);
  });

  return returnArr;
};

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  roleType: any = sessionStorage.getItem('claimRoleType');
  cCode: any = sessionStorage.getItem('uid');
  allChats = [];
  allChatcount: any;
  patArr = [];
  read: any;
  count = 0;
  isham = false;
  constructor(
    private cdRef: ChangeDetectorRef,
    public hipaiAuth: AngularFireAuth,
    private detservice: DatalistService,
    private spinnerService: NgxSpinnerService,
    private router: Router
  ) {
    this.name = true;
    this.displayName = this.detservice.getName();

    this.detservice.hambEvent.subscribe((data) => {
      console.log('Event message from Component A: ' + data);
      this.isham = data;
    });

    this.cpId = sessionStorage.getItem('uid');
    firebase.default
    .database()
    .ref('chat_count/')
    .child(this.cCode)
    .on('value', (resp) => {
      this.patArr = [];
      this.patArr = snapshotToArray(resp);
      console.log('RES:' + JSON.stringify(this.patArr));
      this.patArr.forEach((val, key) => {
        console.log(val.key);
        this.ptArray2.forEach((pval, pky) => {
          console.log(pval);
          if (pval.guid === val.key) {
            pval.read = val.read;
            console.log(pval.read);
            if (pval.read === 'false') {
              this.count++;
            }
          }
        });
        console.log(this.count);

      });

      if (this.cdRef && !(this.cdRef as ViewRef).destroyed) {
                      this.cdRef.detectChanges();
                    }

      // if (this.ref && !(this.ref as ViewRef).destroyed) {
      //         this.ref.detectChanges();
      //         this.getPatientList();
      //       }
    });

    // firebase.default
    //   .database()
    //   .ref('chats/')
    //   .on('value', (resp) => {
    //     this.allChats = [];
    //     this.allChats = snapshotToArray(resp);
    //     if (this.allChats.length > 0) {
    //       this.allChatcount = 0;
    //       this.allChats.forEach((val, key) => {
    //         if (val.receiver === this.cCode) {
    //           if (val.read === 'false') {
    //             this.allChatcount++;
    //           } else {
    //           }
    //           setInterval(() => {
    //             if (this.cdRef && !(this.cdRef as ViewRef).destroyed) {
    //               this.cdRef.detectChanges();
    //             }
    //             // this.readmsgcount();
    //           }, 1000);
    //         }
    //       });
    //     }
    //   });
  }
  displayName: string;
  test = 'vvvv';
  isSuperAdmin: boolean;
  name = false;
  items: any = [];
  selectedButton: any = 'home';
  selectedSettings: string;
  selectedHealth: string;
  selectednot: string;
  isSide = false;
  dsp = false;
  chatcount: any;
  scode: any;
  patientdetails = [];
  patDetails: any;
  ptArray = [];
  ptArray1 = '';
  ptArray2 = [];
  chats = [];
  patientId: any;
  cpId: any;
  removeSpace = false;

  ngOnInit() {
    this.scode = sessionStorage.getItem('uid');
    this.ptArray1 = sessionStorage.getItem('ptArray');
    this.ptArray2 = JSON.parse(this.ptArray1);
    // seetting type of user and display name
    this.isSuperAdmin = JSON.parse(sessionStorage.getItem('superadminType'));
    if (this.isSuperAdmin) {
    } else {
      this.getChatCount();
      this.getPatientList();
    }
    this.items = [{ name: 'Home' }, { name: 'Settings' }];
    this.displayName = sessionStorage.getItem('uname');
    // this.patientId = sessionStorage.getItem('patetId');
  }

  getChatCount() {
    firebase.default
      .database()
      .ref('chats/')
      .on('value', (resp) => {
        this.chats = [];
        this.chats = snapshotToArray(resp);
      });
  }

  navigate() {
    if(this.ptArray.length > 0) {
      this.router.navigate(['/system/web-chat/chat', this.ptArray[0].guid]);
    } else {
      console.log('no patients found');

    }

  }

  async getPatientList() {
    this.patientdetails = await this.detservice.getPatientofCareprovider(
      this.scode
    );
    console.log(this.patientdetails.length);
    if(this.patientdetails.length > 0) {
      this.patientdetails.forEach((val, key) => {
        this.patDetails = val.subscriptions[0];
        this.chatcount = 0;
        for (const i of this.chats) {
          if (this.scode === i.receiver) {
            if (i.sender === val.guid) {
              if (i.read === 'false') {
                this.chatcount = i.read
              } else {
              }
            }
          }
        }
        const obj = {
          name: this.patDetails.patientName,
          gender: this.patDetails.gender,
          age: this.patDetails.age,
          date: this.patDetails.updatedAt,
          ccount: this.chatcount,
          email: this.patDetails.patientEmail,
          mobileNumber: this.patDetails.patientNumber,
          guid: val.guid,
          path: val.path,
        };
        this.ptArray.push(obj);
      });
      sessionStorage.setItem('patetId', this.ptArray[0].guid);
      this.detservice.setPatient(this.ptArray[0]);
      sessionStorage.setItem('pname', this.ptArray[0].name);
      sessionStorage.setItem('patimg', this.ptArray[0].path);
      sessionStorage.setItem('patage', this.ptArray[0].age);
      sessionStorage.setItem('patgender', this.ptArray[0].gender);
      sessionStorage.setItem('patemailId', this.ptArray[0].email);
      sessionStorage.setItem('patmobile', this.ptArray[0].mobileNumber);
      const jsonData = JSON.stringify(this.ptArray);
      sessionStorage.setItem('ptArray', jsonData);
    }


    // this.allChatcount = 0;
    // this.ptArray.forEach((er) => {
    //   this.allChatcount = this.allChatcount + er.ccount;
    // })
  }
  openNav() {
    // Below code is to expand and collapse the side menu bar.
    this.isSide = !this.isSide;
    this.removeSpace = !this.removeSpace;
    console.log('fff'+this.removeSpace);
    if (this.isSide) {
      this.dsp = true;
      document.getElementById('mySidebar').style.width = '250px';
      // document.getElementById('mains').style.marginLeft = '250px';
     // document.getElementById('mains').style.marginLeft = '178px';
      this.detservice.hamburger(this.isSide);
    } else {
      this.dsp = false;
      document.getElementById('mySidebar').style.width = '70px';
      // document.getElementById('mains').style.marginLeft = '0';
      this.detservice.hamburger(this.isSide);
    }
  }
  logout() {
    // Below code is logout the user.
    this.hipaiAuth.signOut().then((resp) => {
      console.log(resp);
      this.router.navigate(['/']);
    });
  }
  // I get called once when the component is about to be destroyed.

  // settings($event, page) {
  //   this.selectedButton = '';
  //   this.selectedSettings = page;
  //   this.selectedHealth = '';
  //   this.router.navigate(['/settings']);
  // }

  // notification($event, page) {
  //   this.selectedButton = '';
  //   this.selectedSettings = '';
  //   this.selectednot = page;
  //   this.selectedHealth = '';
  //   this.router.navigate(['/notifications']);
  // }

  // careprovider($event, page) {
  //   this.selectedButton = '';
  //   this.selectedSettings = '';
  //   this.selectednot = page;
  //   this.selectedHealth = '';
  //   this.router.navigate(['/careprovider']);
  // }

  // home($event, page) {
  //   this.cdRef.detectChanges();
  //   this.selectedButton = page;
  //   this.selectedSettings = '';
  //   this.selectedHealth = '';
  //   this.router.navigate(['/doctorlist']);
  // }

  // health($event, page) {
  //   this.cdRef.markForCheck();
  //   this.selectedButton = '';
  //   this.selectedSettings = '';
  //   this.selectedHealth = page;
  //   this.router.navigate(['/riskfactor']);
  // }
  // Below code is to call the other component to reduce the multiple api hits.
  gotopatients() {
    this.detservice.patClicked('Component A is clicked!!');
    sessionStorage.setItem('fromcareplan', 'No');
  }
  gotoplans() {
    this.detservice.planClicked('Component A is clicked!!');
  }
  gotodash() {
    this.detservice.dashbcliked('Component A is clicked!!');
  }
  gotoinvitepatients() {
    this.detservice.invptClicked('Component A is clicked!!');
  }
}
