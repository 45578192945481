import { ViewportScroller } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'HiPAI Admin';
  subscriptions: Subscription;
  scode: any;

  constructor(
    readonly router: Router,
    readonly viewportScroller: ViewportScroller,
  ) { }

  ngOnInit() {
    this.subscriptions = this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd)
        // ).subscribe(() => window.scrollTo(0,0));
      )
      .subscribe(() => this.viewportScroller.scrollToPosition([0, 0]));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
