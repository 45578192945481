export const AppConfigGroupsConstant = {
    internet: 'Check your Internet Connection',
    networkError: 'NetworkingError',
    somethingWentWrong: 'Something went wrong',
    expired: 'Session Expired.',
    maxlength: 'Name must be at least 3 characters long.',
    invitePatientScreen: {
        nameRequired: 'Name is required',
        genderRequired: 'gender is required',
        ageRequired: 'Age is required',
        idRequired: 'Id is Required',
        mindigitsRequired: 'Mobile Number should have 10 digits',
        validEmailRequired: 'Please enter a valid email address',
        ageRangeRequired: 'Age should be greater than 18'
    },
    criticalDate: {
        titleRequired: 'Title is required',
        dateReuired: 'Please select Date'
    },
    notifications: {
        titleRequired: 'Title is required',
        descriptionRequired: 'Description is required'
    },
    assignCareplan: {
        title: 'Assign Care Plan',
        header: 'All plans are assigned to the patient.',
        noplans: 'No plans are available. Please create a plan to assign!',
        noresult: 'Sorry No Result Found',
        newplan: 'Please create a new plan and submit',
        selectplan: 'Please Select Atleat One Plan',
        createplan: 'CREATE A PLAN'
    }
};
