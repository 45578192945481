import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
  if(window){
    window.console.log=()=>{};
  }
}

if (environment) {
  // console.log('local',environment.API_URL);
  const dynamicScript = 'https://maps.googleapis.com/maps/api/js?key=' + `${environment.firebase.apiKey}` +
   '&libraries=places&language=en';
  const scriptElm = document.createElement('script');
  // scriptElm.onload = function() {
  //  // console.log("maps loaded");
  //   };
  scriptElm.src = dynamicScript;
  document.body.appendChild(scriptElm);
  // history.pushState(null, null, window.location.href);
  // window.addEventListener('hashchange', (e) => {
  //   // history.pushState(null, null, window.location.href);
  //   // console.log(null, null, window.location.href);
  //   if (window.location.pathname === '/allPatients') {
  //         e.preventDefault();
  //         // alert('ok');
  //       } else {
  //       }
  // });
  // document.addEventListener('backbutton', onBackKeyDown, false);
  // function onBackKeyDown(e) {
  //   if (window.location.pathname === '/allPatients') {
  //     e.preventDefault();
  //   } else {
  //   }
  // }
}


platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
