import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginGuard } from '../app/authorization.guard';
import { PdfViewerComponent } from './pdf-viewer/pdf-viewer.component';

const routes: Routes = [
  { path: '', redirectTo: 'auth', pathMatch: 'full' },
  { path: 'auth', loadChildren: () => import('./auth-pages/auth.module').then(m => m.AuthPagesModule)},
  { path: 'no-auth', loadChildren: () => import('./no-auth-pages/no-auth.module').then(m => m.NoAuthPagesModule) },
  { path: 'system', loadChildren: () => import('./system/system.module').then(m => m.SystemModule) },
  { path: 'symptomsRecord/pdf', component: PdfViewerComponent },
];
@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      initialNavigation: 'enabled',
      scrollPositionRestoration: 'enabled'
    }
  )],
  exports: [RouterModule],
})
export class AppRoutingModule { }
