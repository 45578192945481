import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sideDateFormat'
})
export class SideDateFormatPipe implements PipeTransform {

  date;
  diff;
  daydiff;
  transform(value: string) {

    const _value = Number(value);

    const dif = Math.floor( ( (Date.now() - _value) / 1000 ) / 86400 );

    if ( dif < 30 ){
         return convertToNiceDate(value);
    }else{
        const datePipe = new DatePipe('en-US');
        value = datePipe.transform(value, 'MMM dd yyyy HH:mm a');
        return value;
    }
 }
}

function convertToNiceDate(time: string) {
 this.date = new Date(time),
     this.diff = (((new Date()).getTime() - this.date.getTime()) / 1000),
     this.daydiff = Math.floor(this.diff / 86400);

 if (isNaN(this.daydiff) || this.daydiff < 0 || this.daydiff >= 31)
     return '';

 return this.daydiff === 0 && (
     this.diff < 60 && 'Just now' ||
     this.diff < 120 && '1 minute ago' ||
     this.diff < 3600 && Math.floor(this.diff / 60) + ' minutes ago' ||
     this.diff < 7200 && '1 hour ago' ||
     this.diff < 86400 && Math.floor(this.diff / 3600) + ' hours ago') ||
     this.daydiff === 1 && 'Yesterday' ||
     this.daydiff < 7 && this.daydiff + ' days ago' ||
     this.daydiff < 31 && Math.ceil(this.daydiff / 7) + ' week(s) ago';
}
