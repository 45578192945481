import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
@Pipe({
  name: 'formatDate',
})
export class FormatDatePipe extends DatePipe implements PipeTransform {
  // transform(value: any, ...args: any[]): any {
  //   return super.transform(value, "MMMM d , y");
  // }
  // customising pipe
  transform(value: any, ...args: any[]): any {
    const dateArr = value.split('-');
    let result = '';
    for (let i = dateArr.length - 1; i >= 0; i--) {
      result = result + dateArr[i] + '-';
    }
    result = result.substring(0, result.length - 1);
    return super.transform(result, 'd MMM y');
  }
}
