import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateText'
})
export class TruncateTextPipe implements PipeTransform {
  transform(value: string, limit = 25, completeWords = false, ellipsis = '...') {
    if (completeWords) {
      limit = value.substr(0, limit).lastIndexOf(' ');
    }
    return value.length > limit ? value.substr(0, limit) + ellipsis : value;
  }

// transform(value: string, limit: number, trail: string = '…'): string {
//     let result = value || '';
//         if (value) {
//     const words = value.split(/\s+/);
//     if (words.length > Math.abs(limit)) {
//         if (limit < 0) {
//         limit *= -1;
//         result = trail + words.slice(words.length - limit, words.length).join(' ');
//         } else {
//         result = words.slice(0, limit).join(' ') + trail;
//         }
//     }
//     }
//     return result;
// }

}
