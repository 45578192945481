import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MalihuScrollbarModule } from 'ngx-malihu-scrollbar';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';
import { AngularFireModule } from '@angular/fire';
import { OrderModule } from 'ngx-order-pipe';
import { AngularFirestoreModule } from '@angular/fire/firestore';
// import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorageModule, AngularFireStorage } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { CalendarModule, DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { environment } from '../environments/environment';
import { AngularFireAnalyticsModule, ScreenTrackingService, CONFIG, UserTrackingService } from '@angular/fire/analytics';
import { LoginComponent } from './auth-pages/login/login.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { HttpClientModule } from '@angular/common/http';
import { LoginGuard } from '../app/authorization.guard';
import { TagInputModule } from 'ngx-chips';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { from } from 'rxjs';
import { CKEditorModule } from '../ckeditor/ckeditor.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PdfViewerComponent } from './pdf-viewer/pdf-viewer.component';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { DragDropModule} from '@angular/cdk/drag-drop';
import {NgxImageCompressService} from 'ngx-image-compress';
import { ChartModule } from 'angular2-highcharts';
// import * as highcharts from 'Highcharts';
import { HighchartsStatic } from 'angular2-highcharts/dist/HighchartsService';
import { NgxSpinnerModule } from 'ngx-spinner';
import { TrendModule } from 'ngx-trend';
import { InternationalPhoneNumber2Module } from 'ngx-international-phone-number2';
import { NgSelectModule } from '@ng-select/ng-select';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
// import { IgxTimePickerModule } from 'igniteui-angular';
import { ToastrModule } from 'ngx-toastr';

// import { LightboxModule } from 'ngx-lightbox';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import {MatButtonModule} from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { SharedComponentsModule } from './shared-components/shared-components.module';
import { AuthPagesModule } from './auth-pages/auth.module';

declare var require: any;
export function highchartsFactory() {
  const hc = require('highcharts');
  const dd = require('highcharts/modules/drilldown');
  dd(hc);
  return hc;
}
@NgModule({
  declarations: [
    AppComponent,
    PdfViewerComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    CalendarModule,
    NgSelectModule,
   // AngularFireDatabase,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule.enablePersistence(),
    NgxSpinnerModule,
    TrendModule,
    // NgMultiSelectDropDownModule.forRoot(),
    AngularFireAuthModule,
    AngularFireStorageModule,
    MalihuScrollbarModule.forRoot(),
    CKEditorModule,
    ChartModule,
    AngularFireDatabaseModule,
    ImageCropperModule,
    OrderModule,
    NgxPaginationModule,
    TagInputModule,
    BrowserAnimationsModule,
    GooglePlaceModule,
    PdfViewerModule,
    Ng2TelInputModule,
    DragDropModule,
    AngularFireAnalyticsModule,
    InternationalPhoneNumber2Module,
    NgxMaterialTimepickerModule,
    // IgxTimePickerModule
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatButtonModule,
    MatChipsModule,
    MatIconModule,
    // LightboxModule
    ToastrModule.forRoot(),
    DatePickerModule,
    SharedComponentsModule,
  ],
  providers: [
    DatePipe,
    { provide: CONFIG, useValue: {
      send_page_view: true,
      allow_ad_personalization_signals: false,
      anonymize_ip: true
    } },
    // { provide: DEBUG_MODE, useValue: true},
    { provide: LoginGuard, useClass: LoginGuard },
    {
      provide: HighchartsStatic,
      useFactory: highchartsFactory
      },
    { provide: AngularFireStorage, useValue: environment.firebase.storageBucket },
    NgxImageCompressService,
    TitleCasePipe,
    ScreenTrackingService,
    UserTrackingService
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  bootstrap: [AppComponent],
})
export class AppModule {

}
