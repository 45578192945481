import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { DatalistService } from './shared/services/datalist.service';
import { Observable } from 'rxjs';
@Injectable()
export class LoginGuard implements CanActivate {

    constructor(private identityService: DatalistService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (sessionStorage.getItem('isLoggedIn')) { // determine if the uder is logged in from this method.
            return true;
        }
         // Store the attempted URL for redirecting later
     //    this.identityService.redirectUrl = state.url;
        this.router.navigate(['/'], { queryParams: { returnUrl: state.url }});
        return false;
    }
}
