import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';



@Pipe({
  name: 'duplicateData',
  pure: false
})
export class DuplicateDataPipe implements PipeTransform {

    transform(value: any): any {
        if (value !== undefined && value !== null) {
            return _.uniqBy(value, 'city');
        }
        return value;
    }

}
