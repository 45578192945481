import { Injectable, OnDestroy, EventEmitter, Output, isDevMode } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase/app';
import AuthProvider = firebase.auth.AuthProvider;
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { Subject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject, from } from 'rxjs';
import { map } from 'rxjs/operators';
// import { User } from 'firebase';
import { environment } from '../../../environments/environment';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { AppConfigGroupsConstant } from 'src/app/common/constant';
// import { Observable } from 'rxjs/Observable';
// import { Subject } from 'rxjs/Subject';
@Injectable({
  providedIn: 'root',
})
export class DatalistService implements OnDestroy {
  @Output() aClickedEvent = new EventEmitter<string>();
  @Output() hambEvent = new EventEmitter<boolean>();
  @Output() patClickedEvent = new EventEmitter<string>();
  @Output() planClickedEvent = new EventEmitter<string>();
  @Output() dashClickedEvent = new EventEmitter<string>();
  @Output() invpatClickedEvent = new EventEmitter<string>();
  @Output() bClickedEvent = new EventEmitter<string>();
  @Output() cClickedEvent = new EventEmitter<string>();
  @Output() cMobClickedEvent = new EventEmitter<string>();
  @Output() aClickedEventImg = new EventEmitter<string>();
  private ngUnsubscribe: Subject<void> = new Subject();
  itemCollection: AngularFirestoreCollection;
  items: Observable<[]>;
  // user: User;
  constructor(
    public db: AngularFirestore,
    private http: HttpClient,
    public hipaiAuth: AngularFireAuth,
    public router: Router,
    private analytics: AngularFireAnalytics,
  ) {

     console.log(isDevMode());
    // this.hipaiAuth.authState.subscribe(user => {
    //   if (user) {
    //     this.user = user;
    //     localStorage.setItem('user', JSON.stringify(this.user));
    //   } else {
    //     localStorage.setItem('user', null);
    //   }
    // });
  }

  doctorsdata = [];
  idToken: any;
  profdta: any;
  gender: string;
  higrskpat: any;
  categ: any;
  planName: any;
  patntData: any;
  redirectUrl: string;
  isLoggedIn: string;
  healthData: any;
  riskInf: any;
  patientObj: any;
  dname: string;
  userId: string;
  img: string;
  measureData: any;
  cpdata: any;
  docterInf: string;
  sysactData: any;
  planInf: any;
  useruid: any;
  uid: any;
  newvalue: any;
  invpdata: any;
  userInf: any;
  token: any;
  careprodata: any;
  key = environment.firebase.apiKey;
  URL = environment.API_URL;
  AccessToken = environment.AccessToken;
  signInWithGoogle() {
    this.isLoggedIn = 'true';
    sessionStorage.setItem('isLoggedIn', this.isLoggedIn);
    console.log('Sign in with google');
    return this.oauthSignIn(new firebase.auth.GoogleAuthProvider());
  }
  public logEvent(eventName: string, eventParams: any = {}): void {
    if (firebase.analytics !== undefined) {
      const analytics = firebase.analytics();
      analytics.logEvent(eventName, eventParams);
    } else {
    }
  }
  translate(obj) {
    return this.http.post('https://translation.googleapis.com/language/translate/v2?key=' + this.key, obj);
  }
  setUserId(userInf) {
    const analytics = firebase.analytics();
    analytics.setUserId(userInf);
  }
  AClicked(msg: string) {
    this.aClickedEvent.emit(msg);
  }
  hamburger(msg: boolean) {
    this.hambEvent.emit(msg);
  }
  patClicked(msg: string) {
    this.patClickedEvent.emit(msg);
  }
  planClicked(msg: string) {
    this.planClickedEvent.emit(msg);
  }
  dashbcliked(msg: string) {
    this.dashClickedEvent.emit(msg);
  }
  invptClicked(msg: string) {
    this.invpatClickedEvent.emit(msg);
  }
  BClicked(msg: string) {
    this.bClickedEvent.emit(msg);
  }
  CClicked(msg: string) {
    this.cClickedEvent.emit(msg);
  }
  CMobClicked(msg: string) {
    this.cMobClickedEvent.emit(msg);
  }
  imgclicked(msg: string) {
    this.aClickedEventImg.emit(msg);
  }
  private oauthSignIn(provider: AuthProvider): any {
    if (!(window as any).cordova) {
      //  this.isLoggedIn = true;
      this.isLoggedIn = sessionStorage.getItem('isLoggedIn');
      return this.hipaiAuth.signInWithPopup(provider);
    } else {
      this.hipaiAuth.signInWithRedirect(provider).then(() => {
        return this.hipaiAuth
          .getRedirectResult()
          .then((result) => {
            // This gives you a Google Access Token.
            // You can use it to access the Google API.
            this.token = result.credential;
            // The signed-in user info.
            const user = result.user;

            console.log('vikas', this.token, user);
          })
          .catch((error) => {
            // Handle Errors here.
            alert(error.message);
          });
      });
    }
  }
  getErrors(response, type?: string) {
   if (response.status === 0) {
        return AppConfigGroupsConstant.internet;
    } else if (response.status === 502 || response.status === 504 || response.status === 500) {
        return AppConfigGroupsConstant.somethingWentWrong;
    } else if (response.status === 403 || response.status === 401) {
        this.logout();
        return AppConfigGroupsConstant.expired;
    } else {
        return AppConfigGroupsConstant.somethingWentWrong;
    }
}
  logout() {
    this.hipaiAuth.signOut().then(
      () => {
        // console.log(resp);
        // location.reload(true);
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
        sessionStorage.removeItem('isLoggedIn');
        // sessionStorage.removeItem('claimRoleType');
        sessionStorage.removeItem('uid');
        sessionStorage.removeItem('uname');
        sessionStorage.removeItem('image');
        sessionStorage.removeItem('superadminType');
        sessionStorage.removeItem('scode');
        sessionStorage.removeItem('idtoken');
        sessionStorage.removeItem('cpuid');
        localStorage.removeItem('user');
        localStorage.removeItem('isText');
        sessionStorage.removeItem('isStr');
        sessionStorage.removeItem('ishyr');
        sessionStorage.removeItem('isPat');
        sessionStorage.removeItem('isPaln');
        sessionStorage.removeItem('isCP');
        sessionStorage.removeItem('isInvited');
        sessionStorage.removeItem('isView');
        sessionStorage.removeItem('setDataItem');
        sessionStorage.removeItem('setDataItem1');
        localStorage.removeItem('isSym');
        sessionStorage.removeItem('isCP');
        sessionStorage.removeItem('isInvited');
        sessionStorage.removeItem('plname');
        sessionStorage.removeItem('fromcareplan');
        sessionStorage.removeItem('pname');
        sessionStorage.removeItem('patimg');
        sessionStorage.removeItem('patage');
        sessionStorage.removeItem('patgender');
        sessionStorage.removeItem('patemailId');
        sessionStorage.removeItem('patmobile');
        sessionStorage.removeItem('ptArray');
        sessionStorage.removeItem('patetId');
        sessionStorage.removeItem('phyArr');
        sessionStorage.removeItem('schApObj');
        sessionStorage.removeItem('phyArray');
        sessionStorage.removeItem('saPatName');
        sessionStorage.removeItem('saPatDate');
        sessionStorage.removeItem('saPatTime');
        sessionStorage.removeItem('saPatDoc');
        this.setcareplanData(undefined);
        this.setpatientData(undefined);
        this.sethighrskpatient(undefined);
        this.setinvpatData(undefined);
        // this.router.navigate(['/']);
        if (sessionStorage.getItem('claimRoleType') === 'clinic') {
          sessionStorage.removeItem('claimRoleType');
          this.router.navigate(['/auth/careprovider-login']);
          sessionStorage.removeItem('code');
         // this.router.navigate(['/careprovider-login']);
        } else if (sessionStorage.getItem('claimRoleType') === 'nonclinic') {
          sessionStorage.removeItem('claimRoleType');
          this.router.navigate(['/auth/admin-login']);
        } else {
        }
        //  window.location.reload();
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getData(doctname) {
    // return this.db
    //   .collection('physicians-base', (ref) => ref.limit(100)).valueChanges()
    //   .pipe(takeUntil(this.ngUnsubscribe));
    return this.db
      .collection('physicians-base', (ref) =>
        ref.limit(100).where('firstName', '==', doctname)
      )
      .valueChanges();
    //   .pipe(takeUntil(this.ngUnsubscribe));
    // return this.db.collection('physicians-base', ref => ref.limit(100).
    // where(('firstName' || 'middleName' || 'lastName'), '==', doctname)).valueChanges();

    // this.itemCollection = this.db.collection('physicians-base', ref => {
    //   // Compose a query using multiple .where() methods
    //   return ref.where('firstName', '==', doctname)
    //           .where('lastName', '==', doctname)
    //           .where('middleName', '==', doctname)
    //           // .where('price', '>', 10)
    //           // .where('price', '<', 100)
    // });
    //  return this.itemCollection.valueChanges();
  }
  getdatafirtsName(doctname) {
    return this.db
      .collection('physicians-base', (ref) =>
        ref.limit(100).where('firstName', '==', doctname)
      )
      .valueChanges();
  }
  getdataMiddleName(doctname) {
    return this.db
      .collection('physicians-base', (ref) =>
        ref.limit(100).where('middleName', '==', doctname)
      )
      .valueChanges();
  }

  getdatalastName(doctname) {
    return this.db
      .collection('physicians-base', (ref) =>
        ref.limit(100).where('lastName', '==', doctname)
      )
      .valueChanges();
  }

  getdatafirtsNameCity(doctname, city) {
    return this.db
      .collection('physicians-base', (ref) =>
        ref
          .limit(100)
          .where('firstName', '==', doctname)
          .where('city', '==', city)
      )
      .valueChanges();
  }
  getdataMiddleNameCity(doctname, city) {
    return this.db
      .collection('physicians-base', (ref) =>
        ref
          .limit(100)
          .where('middleName', '==', doctname)
          .where('city', '==', city)
      )
      .valueChanges();
  }

  getdatalastNameCity(doctname, city) {
    return this.db
      .collection('physicians-base', (ref) =>
        ref
          .limit(100)
          .where('lastName', '==', doctname)
          .where('city', '==', city)
      )
      .valueChanges();
  }
  // getlocationDoctor(doctname, city) {
  //  return this.db.collection('physicians-base', ref => ref.limit(100).
  // where('firstName', '==', doctname).where('city', '==', city)).valueChanges();
  // }
  getMale(M, city) {
    return this.db
      .collection('physicians-base', (ref) =>
        ref.limit(100).where('gender', '==', M).where('city', '==', city)
      )
      .valueChanges();

    // return this.db.collection('physicians-base', ref => ref.limit(10).where('gender', '==', M)).valueChanges()
    // .pipe(takeUntil(this.ngUnsubscribe));
  }

  getFemale(F, city) {
    return this.db
      .collection('physicians-base', (ref) =>
        ref.limit(100).where('gender', '==', F).where('city', '==', city)
      )
      .valueChanges();

    // return this.db.collection('physicians-base', ref => ref.limit(10).where('gender', '==', F)).valueChanges()
    // .pipe(takeUntil(this.ngUnsubscribe));
  }

  getgenderlocname(dname, city) {
    return this.db
      .collection('physicians-base', (ref) =>
        ref.limit(100).where('city', '==', city).where('firstName', '==', dname)
      )
      .valueChanges();
  }

  getdocData(id) {
    return this.db
      .collection('physicians-base', (ref) => ref.where('npi', '==', id))
      .valueChanges()
      .pipe(takeUntil(this.ngUnsubscribe));
  }

  updateDoctor(doct) {
    //    delete doct.id;
    this.db.doc('physicians-base/' + doct.npi).update(doct);
  }

  createDoctor(doct) {
    // return this.db.collection('physicians-base').add(doct);
    return this.db.collection('physicians-base').doc(doct.npi).set(doct);
  }
  createRiskfactor(riskObj, uid) {
    riskObj.status = false;
    return this.db.collection('riskfactors').doc(uid).set(riskObj);
  }

  // createNotification(notObj, uid) {
  //   return this.db.collection('notifications-all').doc(uid).set(notObj);
  // }
  getriskonlyMale(M) {
    return this.db
      .collection('riskfactors', (ref) =>
        ref.limit(100).where('gender', '==', M)
      )
      .valueChanges()
      .pipe(takeUntil(this.ngUnsubscribe));
  }
  getriskMale(M, group) {
    return this.db
      .collection('riskfactors', (ref) =>
        ref.limit(100).where('gender', '==', M).where('ageGroup', '==', group)
      )
      .valueChanges()
      .pipe(takeUntil(this.ngUnsubscribe));
  }
  getriskonlyFemale(F) {
    return this.db
      .collection('riskfactors', (ref) =>
        ref.limit(100).where('gender', '==', F)
      )
      .valueChanges()
      .pipe(takeUntil(this.ngUnsubscribe));
  }
  getriskonlyFemaleage(F) {
    return this.db
      .collection('riskfactors', (ref) =>
        ref.limit(100).where('ageGroup', '==', F)
      )
      .valueChanges()
      .pipe(takeUntil(this.ngUnsubscribe));
  }
  getriskFemale(F, group) {
    return this.db
      .collection('riskfactors', (ref) =>
        ref.limit(100).where('gender', '==', F).where('ageGroup', '==', group)
      )
      .valueChanges()
      .pipe(takeUntil(this.ngUnsubscribe));
  }
  getriskbyuuid(uuid) {
    return this.db
      .collection('riskfactors', (ref) => ref.where('uuid', '==', uuid))
      .valueChanges()
      .pipe(takeUntil(this.ngUnsubscribe));
  }

  getriskagegroup(gender, ageGroup) {
    // console.log(ageGroup);
    return this.db
      .collection('riskfactors', (ref) =>
        ref
          .limit(100)
          .where('ageGroup', '==', ageGroup)
          .where('gender', '==', gender)
      )
      .valueChanges()
      .pipe(takeUntil(this.ngUnsubscribe));
  }

  getAllRisks() {
    return this.db
      .collection('riskfactors', (ref) => ref.limit(100))
      .valueChanges()
      .pipe(takeUntil(this.ngUnsubscribe));
  }

  getAllNotifications() {
    return this.db
      .collection('notifications-all')
      .valueChanges()
      .pipe(takeUntil(this.ngUnsubscribe));
  }
  updateRiskfact(risk, uuid) {
    //    delete doct.id;
    this.db.doc('riskfactors/' + uuid).update(risk);
  }

  deleteRisk(uuid) {
    this.db
      .collection('riskfactors')
      .doc(uuid)
      .delete()
      .then(() => {
        // console.log('Document successfully deleted!');
      })
      .catch(() => {
        // console.error('Error removing document: ', error);
      });
    // this.db.doc('riskfactors/' + uuid).delete();
  }

  deletePlan(uuid) {
    this.db
      .collection('added-care-plans')
      .doc(uuid)
      .delete()
      .then(() => {
        // console.log('Document successfully deleted!');
      })
      .catch(() => {
        // console.error('Error removing document: ', error);
      });
    // this.db.doc('riskfactors/' + uuid).delete();
  }

  createActionItems(riskObj, uid) {
    const actObj = {
      actions: riskObj.actions,
      riskfactorId: uid,
    };
    return this.db.collection('risk-factor-actions').doc(uid).set(actObj);
  }

  updateImage(doct) {
    this.db
      .doc('physicians-base/' + doct.npi)
      .update({ imageURL: doct.imageURL });
  }

  updateriskImage(doct) {
    this.db.doc('riskfactors/' + doct.uuid).update({ imageURL: doct.imageURL });
  }

  updateplanImage(doct, id) {
    this.db.doc('care-plans/' + id).update({ imageURL: doct.imageURL });
  }

  getCity(city) {
    // return this.db
    //   .collection('physicians-base', (ref) => ref)
    //   .valueChanges();

    return this.db
      .collection('physicians-base', (ref) =>
        ref.limit(100).where('city', '==', city)
      )
      .valueChanges()
      .pipe(takeUntil(this.ngUnsubscribe));
  }

  getmultiquery(city, gender, doctname) {
    this.itemCollection = this.db.collection('physicians-base', (ref) => {
      // Compose a query using multiple .where() methods
      return (
        ref
          .limit(100)
          .where('lastName', '==', doctname)
          // .where('lastName', '==', doctname)
          // .where('middleName', '==', doctname)
          .where('city', '==', city)
          .where('gender', '==', gender)
      );
    });
    return this.itemCollection.valueChanges();
  }

  getmultiqueryfirst(city, gender, doctname) {
    this.itemCollection = this.db.collection('physicians-base', (ref) => {
      // Compose a query using multiple .where() methods
      return (
        ref
          .limit(100)
          .where('firstName', '==', doctname)
          // .where('lastName', '==', doctname)
          // .where('middleName', '==', doctname)
          .where('city', '==', city)
          .where('gender', '==', gender)
      );
    });
    return this.itemCollection.valueChanges();
  }
  getmultiquerymiddle(city, gender, doctname) {
    this.itemCollection = this.db.collection('physicians-base', (ref) => {
      // Compose a query using multiple .where() methods
      return (
        ref
          .limit(100)
          .where('middleName', '==', doctname)
          // .where('lastName', '==', doctname)
          // .where('middleName', '==', doctname)
          .where('city', '==', city)
          .where('gender', '==', gender)
      );
    });
    return this.itemCollection.valueChanges();
  }

  setName(name) {
    this.dname = name;
  }
  getName() {
    return this.dname;
  }
  setImage(image) {
    this.img = image;
  }

  setID(id) {
    this.idToken = id;
  }

  getImage() {
    return this.img;
  }
  setdocterData(doc) {
    this.docterInf = doc;
  }

  setriskData(risk) {
    this.riskInf = risk;
  }
  setcareplanData(pdata) {
    this.cpdata = pdata;
  }
  setcareproviderdata(cdata) {
    this.careprodata = cdata;
  }
  getcareprodata() {
    return this.careprodata;
  }
  setHealthData(hdata) {
    this.healthData = hdata;
  }
  setsysActions(sadata) {
    this.sysactData = sadata;
  }
  getsysActions() {
    return this.sysactData;
  }
  setmeasurementData(mdata) {
    this.measureData = mdata;
  }
  getmeasurementData() {
    return this.measureData;
  }
  getHealthData() {
    return this.healthData;
  }
  setCategory(cate) {
    this.categ = cate;
  }
  setProfile(pfdata) {
    this.profdta = pfdata;
  }

  getProfile() {
    return this.profdta;
  }

  getCategory() {
    return this.categ;
  }
  getcareplanData() {
    return this.cpdata;
  }
  setinvpatData(pdata) {
    this.invpdata = pdata;
  }
  getinvpatData() {
    return this.invpdata;
  }
  setpatientData(ptdata) {
    this.patntData = ptdata;
  }
  getpatientData() {
    return this.patntData;
  }
  sethighrskpatient(rsdata) {
    this.higrskpat = rsdata;
  }
  gethighrskpatient() {
    return this.higrskpat;
  }
  getriskData() {
    return this.riskInf;
  }
  setplanData(risk) {
    this.planInf = '';
    this.planInf = risk;
  }
  getplanData() {
    return this.planInf;
  }

  setplanName(pname) {
    this.planName = pname;
  }

  getplanName() {
    return this.planName;
  }

  getdoctorData() {
    return this.docterInf;
  }



// create doctor

createDoctorApi(pushObj): Promise<any> {
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: 'Basic ODBiMzA0MjAtMjgxOS00ZDUxLWFjNDUtNWRlMGJjZDNhZDdk',
  });
  const options = { headers };
  return this.http
    .post('', pushObj, options)
    .pipe(takeUntil(this.ngUnsubscribe))
    .toPromise();
}

// create riskfactor

createRiskFactor(pushObj): Promise<any> {
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
     Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user')).stsTokenManager.accessToken
  });
  const options = { headers };
  return this.http
    .post('https://us-central1-hipai-mobile-test.cloudfunctions.net/hipai/v1/riskfactors', pushObj, options)
    .pipe(takeUntil(this.ngUnsubscribe))
    .toPromise();
}

// create care plan

createCarePlan(pushObj): Promise<any> {
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
     Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user')).stsTokenManager.accessToken
  });
  const options = { headers };
  return this.http
    .post(this.URL + `carePlans`, pushObj, options)
    .pipe(takeUntil(this.ngUnsubscribe))
    .toPromise();
}

// add actions
addActions(pushObj): Promise<any> {
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
     Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user')).stsTokenManager.accessToken
  });
  const options = { headers };
  return this.http
    .post(this.URL + `patient/actions`, pushObj, options)
    .pipe(takeUntil(this.ngUnsubscribe))
    .toPromise();
}
// get all care plans

getallcarePlans(): Promise<any> {
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
     Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user')).stsTokenManager.accessToken
  });
  const options = { headers };
  return this.http.get(this.URL + `carePlans`, options).toPromise();
}
// get health measures

getHealthMeasures(id): Promise<any> {
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
     Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user')).stsTokenManager.accessToken
  });
  const options = { headers };
  return this.http.get(this.URL + `careProvider/getPatientCarePlanHealthMeasures?userId=`
  + id, options).toPromise();
}

// //getAllCareproviders

getAllcareProviders(): Promise<any> {
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
     Authorization: 'Bearer ' + sessionStorage.getItem('idtoken')
  });
  const options = { headers };
  return this.http.get(this.URL + `careProvider`, options).toPromise();
}
// add categories

addcategory(upobj): Promise<any> {
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
     Authorization: 'Bearer ' + sessionStorage.getItem('idtoken')
  });
  const options = { headers };
  return this.http.put(this.URL + `admin/categories`, upobj, options).toPromise();
}

/// update categories

updcategory(upobj): Promise<any> {
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
     Authorization: 'Bearer ' + sessionStorage.getItem('idtoken')
  });
  const options = { headers };
  return this.http.put(this.URL + `admin/subcategories`, upobj, options).toPromise();
}

// get all patients list of care provider
getPatientofCareprovider(id): Promise<any> {
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
     Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user')).stsTokenManager.accessToken
  });
  const options = { headers };
  return this.http.get(this.URL + `careProvider/invitePatients?careProviderId=`
  + id, options).toPromise();
}
// resend invt for careprovider

resendInvt(id): Promise<any> {
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
     Authorization: 'Bearer ' + sessionStorage.getItem('idtoken')
  });
  const options = { headers };
  return this.http.get(this.URL + `careProvider/resendInvitation?cpuid=`
  + id, options).toPromise();
}
// getplan details

getPlanDetails(id): Promise<any> {
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
     Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user')).stsTokenManager.accessToken
  });
  const options = { headers };
  return this.http.get(this.URL + `carePlans/id?id=`
  + id, options).toPromise();
}

// getpatient care plan info
getPatientCarePlan(id): Promise<any> {
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
     Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user')).stsTokenManager.accessToken
  });
  const options = { headers };
  return this.http.get(this.URL + `careProvider/getPatientCarePlanInfo?userId=`
  + id, options).toPromise();
}



// Delete care plan by id

deletecarePlans(id): Promise<any> {
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
     Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user')).stsTokenManager.accessToken
  });
  const options = { headers };
  return this.http.delete(this.URL + `carePlans?id=` + id, options).toPromise();
}

// update careplan by id

updatecarePlans(id, upobj): Promise<any> {
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
     Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user')).stsTokenManager.accessToken
  });
  const options = { headers };
  return this.http.put(this.URL + `carePlans/activities?id=`
   + id, upobj, options).toPromise();
}


// criticalDate api
criticaldate(id, upobj): Promise<any> {
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
     Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user')).stsTokenManager.accessToken
  });
  const options = { headers };
  return this.http.put(this.URL + `careProvider/patient/creticalDate?userId=`
   + id, upobj, options).toPromise();
}
// update careprovider status

updatecareproStatus(id, upobj): Promise<any> {
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
     Authorization: 'Bearer ' + sessionStorage.getItem('idtoken')
  });
  const options = { headers };
  return this.http.put(this.URL + `carePlans/careprovider/id?careProviderId=`
   + id, upobj, options).toPromise();
}

sequence(id, upobj): Promise<any> {
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
     Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user')).stsTokenManager.accessToken
  });
  const options = { headers };
  return this.http.put(this.URL + `carePlans/activities/sequence?id=`
   + id, upobj, options).toPromise();
}
// update careplan only
updatecarePlanOnly(id, upobj): Promise<any> {
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
     Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user')).stsTokenManager.accessToken
  });
  const options = { headers };
  return this.http.put(this.URL + `carePlans?id=` + id, upobj, options).toPromise();
}

// change status of symptom recorder
symptomStatus(obj, pid): Promise<any> {
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
     Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user')).stsTokenManager.accessToken
  });
  const options = { headers };
  return this.http.put(this.URL + `symptomRecorder/read?patientId=` + pid , obj, options).toPromise();
}

// change status of symptom recorder
healthcountupd(obj, pid): Promise<any> {
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
     Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user')).stsTokenManager.accessToken
  });
  const options = { headers };
  return this.http.put(this.URL + `healthMeasuresActivity/read?patientId=` + pid , obj, options).toPromise();
}

// desc change for activity

descchange(obj, pid): Promise<any> {
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
     Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user')).stsTokenManager.accessToken
  });
  const options = { headers };
  return this.http.put(this.URL + `careProvider/invitePatients/activity/description?userId=` + pid , obj, options).toPromise();
}

// due date change

duedatechange(obj, pid): Promise<any> {
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
     Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user')).stsTokenManager.accessToken
  });
  const options = { headers };
  return this.http.put(this.URL + `careProvider/invitePatients/activity/dueDate?userId=` + pid , obj, options).toPromise();
}

// addplanactivity

addplanactivity(id, pushObj): Promise<any> {
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
     Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user')).stsTokenManager.accessToken
  });
  const options = { headers };
  return this.http
    .post(this.URL + `carePlans/activities?carePlanId=`
     + id, pushObj, options)
    .pipe(takeUntil(this.ngUnsubscribe))
    .toPromise();
}
// add healthmeasures
addHealthmeasure(pushObj): Promise<any> {
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
     Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user')).stsTokenManager.accessToken
  });
  const options = { headers };
  return this.http
    .post(this.URL + `careProvider/healthMeasuresActivity`, pushObj, options)
    .pipe(takeUntil(this.ngUnsubscribe))
    .toPromise();
}
// add notification
addNotification(notObj): Promise<any> {
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
     Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user')).stsTokenManager.accessToken
  });
  const options = { headers };
  return this.http
    .post(this.URL + `careProvider/patient/notifications`, notObj, options)
    .pipe(takeUntil(this.ngUnsubscribe))
    .toPromise();
}

// upload Image of careprovide
uploadCareImg(upobj): Promise<any> {
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
     Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user')).stsTokenManager.accessToken
  });
  const options = { headers };
  return this.http.put(this.URL + `careProvider/image/upload`
  , upobj, options).toPromise();
}

// mark as high risk pat
markhighrisk(upobj): Promise<any> {
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
     Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user')).stsTokenManager.accessToken
  });
  const options = { headers };
  return this.http.put(this.URL + `careProvider/patient/risk`
  , upobj, options).toPromise();
}

// add groups.
addgroups(upobj): Promise<any> {
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
     Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user')).stsTokenManager.accessToken
  });
  const options = { headers };
  return this.http.put(this.URL + `careProvider/patient/groups`
  , upobj, options).toPromise();
}
// upload Image of careprovide
uploadCareplanImg(upobj): Promise<any> {
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
     Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user')).stsTokenManager.accessToken
  });
  const options = { headers };
  return this.http.put(this.URL + `carePlans/image`
  , upobj, options).toPromise();
}

// upload careplanact image
uploadCareplanactImg(upobj): Promise<any> {
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
     Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user')).stsTokenManager.accessToken
  });
  const options = { headers };
  return this.http.put(this.URL + `carePlans/activities/image`
  , upobj, options).toPromise();
}

// delete careplan of patient

deleteCarePlanPatient(id, upobj): Promise<any> {
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
     Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user')).stsTokenManager.accessToken
  });
  const options = { headers };
  return this.http.put(this.URL + `carePlans/deleteAssignedCarePlanFromPatient?userId=`
   + id, upobj, options).toPromise();
}

// assignplan to patient

assignplanToPatient(upobj, pid): Promise<any> {
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
     Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user')).stsTokenManager.accessToken
  });
  const options = { headers };
  return this.http.put(this.URL + `carePlans/assignToPatient?userId=`
  + pid, upobj, options).toPromise();
}


// update care plan activity

deletecarePlansAct(id, upobj): Promise<any> {
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
     Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user')).stsTokenManager.accessToken
  });
  const options = { headers };
  return this.http.put(this.URL + `carePlans/activities/delete?id=` + id,
   upobj, options).toPromise();
}


// change activity status

changeActivityStatus(id, upobj): Promise<any> {
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
     Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user')).stsTokenManager.accessToken
  });
  const options = { headers };
  return this.http.put(this.URL + `carePlans/changeActivityStatusByCareProvider?planId=` + id,
   upobj, options).toPromise();
}

// change act pdetails
changeActivityPtStatus(id, upobj): Promise<any> {
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
     Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user')).stsTokenManager.accessToken
  });
  const options = { headers };
  return this.http.put(this.URL + `careProvider/invitePatients/activity/status?userId=` + id,
   upobj, options).toPromise();
}
// Change Role

changeRole(id, upobj): Promise<any> {
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
     Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user')).stsTokenManager.accessToken
  });
  const options = { headers };
  return this.http.put(this.URL + `careProvider/changeRole?userId=` + id,
   upobj, options).toPromise();
}

// change patient status

changePatientStatus(id, upobj): Promise<any> {
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
     Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user')).stsTokenManager.accessToken
  });
  const options = { headers };
  return this.http.put(this.URL + `carePlans/invitePatients?invitePatientId=` + id,
   upobj, options).toPromise();
}

// send notinvited patient id
updatePatintID(id, upobj): Promise<any> {
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
     Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user')).stsTokenManager.accessToken
  });
  const options = { headers };
  return this.http.put(this.URL + `careProvider/updatePatientIdByCareProvider?invitePatientId=` + id,
   upobj, options).toPromise();
}

// update patinet profile
updatePatientprofile(id, upobj): Promise<any> {
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
     Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user')).stsTokenManager.accessToken
  });
  const options = { headers };
  return this.http.put(this.URL + `careProvider/updatePatienProfileByCareProvider?userId=` + id,
   upobj, options).toPromise();
}

updatePatientprofilenosuserid(id, upobj): Promise<any> {
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
     Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user')).stsTokenManager.accessToken
  });
  const options = { headers };
  return this.http.put(this.URL + `careProvider/updateInvitedPatienProfileById?invitedPatientId=` + id,
   upobj, options).toPromise();
}
// update careprovider details

updateCareProvider(upobj): Promise<any> {
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
     Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user')).stsTokenManager.accessToken
  });
  const options = { headers };
  return this.http.put(this.URL + `careProvider`,
   upobj, options).toPromise();
}


// get categories

getapicatgries(): Promise<any> {
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
     Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user')).stsTokenManager.accessToken
  });
  const options = { headers };
  return this.http.get(this.URL + `carePlans/categories`, options).toPromise();
}
// get categories for admin
getapicatgriesadmin(): Promise<any> {
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
     Authorization: 'Bearer ' + sessionStorage.getItem('idtoken')
  });
  const options = { headers };
  return this.http.get(this.URL + `carePlans/categories`, options).toPromise();
}
// get health Mesures
getallhealthMeasures(): Promise<any> {
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
     Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user')).stsTokenManager.accessToken
  });
  const options = { headers };
  return this.http.get(this.URL + `healthMeasures`, options).toPromise();
}

// get all sys actions
getallsysActions(): Promise<any> {
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
     Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user')).stsTokenManager.accessToken
  });
  const options = { headers };
  return this.http.get(this.URL + `actions`, options).toPromise();
}

/// get all patiens high risk
getallhighriskpat(): Promise<any> {
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
     Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user')).stsTokenManager.accessToken
  });
  const options = { headers };
  return this.http.get(this.URL + `careProvider/highRisk/patients`, options).toPromise();
}
// get all notifications

getAllNotificationsPatient(id): Promise<any> {
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
     Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user')).stsTokenManager.accessToken
  });
  const options = { headers };
  return this.http.get(this.URL + `careProvider/patient/notifications?userId=`
  + id, options).toPromise();
}

// get all pat action
getAllPatactions(id): Promise<any> {
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
     Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user')).stsTokenManager.accessToken
  });
  const options = { headers };
  return this.http.get(this.URL + `patient/actions?userId=`
  + id, options).toPromise();
}

// get careprovider details
getCareProviderProfile(): Promise<any> {
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
     Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user')).stsTokenManager.accessToken
  });
  const options = { headers };
  // carePlans/careprovider/id
  return this.http.get(this.URL + `carePlans/careprovider/id`, options).toPromise();
}

// get all groups
getAllGroups(): Promise<any> {
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
     Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user')).stsTokenManager.accessToken
  });
  const options = { headers };
  return this.http.get(this.URL + `careProvider/patient/groups`, options).toPromise();
}
// get careprovider for log details
userInfo(): Promise<any> {
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
     Authorization: 'Bearer ' + sessionStorage.getItem('idtoken')
  });
  const options = { headers };
  return this.http.get(this.URL + `user`, options).toPromise();
}

// Invite Patients Api

invitePateint(pushObj): Promise<any> {
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
     Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user')).stsTokenManager.accessToken
  });
  const options = { headers };
  return this.http
    .post(this.URL + `carePlans/invitePatients`, pushObj, options)
    .pipe(takeUntil(this.ngUnsubscribe))
    .toPromise();
}

// forgot password

forgotpwd(emilObj): Promise<any> {
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
    //  Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user')).stsTokenManager.accessToken
  });
  const options = { headers };
  return this.http
    .post(this.URL + `user/generatePasswordResetLink`, emilObj, options)
    .pipe(takeUntil(this.ngUnsubscribe))
    .toPromise();
}

// Invite care provider

inviteCareProvidersAPi(pushObj): Promise<any> {
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
     Authorization: 'Bearer ' + sessionStorage.getItem('idtoken')
  });
  const options = { headers };
  return this.http
    .post(this.URL + `careProvider`, pushObj, options)
    .pipe(takeUntil(this.ngUnsubscribe))
    .toPromise();
}

// get invited patients

getinvitePatients(id,page,perpage): Promise<any> {
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
     Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user')).stsTokenManager.accessToken
  });
  const options = { headers };
  return this.http.get(this.URL + `carePlans/invitePatients?careProviderId=`
  + id+ `&` + `page=` + page + `&` + `perPage=` + perpage, options).toPromise();
}

// All Patients Search API

searchPatients(id, term): Promise<any> {
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
     Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user')).stsTokenManager.accessToken
  });
  const options = { headers };
  return this.http.get(this.URL + `carePlans/invitePatients/search?careProviderId=`
  + id+ `&` + `terms=` + term, options).toPromise();
}

// delete patient
deletepatient(id): Promise<any> {
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
     Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user')).stsTokenManager.accessToken
  });
  const options = { headers };
  return this.http.delete(this.URL + `carePlans/invitePatients?id=`
   + id, options).toPromise();
}


cancelAppointment(id,data): Promise<any> {
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
     Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user')).stsTokenManager.accessToken
  });
  const options = { headers };
  return this.http.put(this.URL + `careProvider/appointments?appointmentId=`
   + id, data, options).toPromise();
}

sendAppointmentlink(data){
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
     Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user')).stsTokenManager.accessToken
  });
  const options = { headers };
  return this.http
  .post(this.URL + `appointments/careProvider/sendAppointmentBookingUrl`, data, options)
  .pipe(takeUntil(this.ngUnsubscribe))
  .toPromise();
}



// delete careprovider image

deleteCareproviderimg(id): Promise<any> {
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
     Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user')).stsTokenManager.accessToken
  });
  const options = { headers };
  return this.http.delete(this.URL + `careProvider/image/delete?careProviderId=`
   + id, options).toPromise();
}

searchDoctor(data){
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
     Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user')).stsTokenManager.accessToken
  });
  const options = { headers };
  return this.http.get(this.URL + `careProvider/searchDoctor?term=`
   + data, options).toPromise();
}

addDoctortoClinic(data){
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
     Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user')).stsTokenManager.accessToken
  });
  const options = { headers };
  return this.http
  .post(this.URL + `careProvider/doctor`, data, options)
  .pipe(takeUntil(this.ngUnsubscribe))
  .toPromise();
}

setAvailability(data){
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
     Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user')).stsTokenManager.accessToken
  });
  const options = { headers };
  return this.http
  .put(this.URL + `calendar/setDoctorAvailability`, data, options)
  .pipe(takeUntil(this.ngUnsubscribe))
  .toPromise();
}

getAlldoctorSlots(data){
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
     Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user')).stsTokenManager.accessToken
  });
  const options = { headers };
  return this.http.get(this.URL + `careProvider/appointments/doctor?physicianDocId=`
  + data.physicianDocId + `&` + `startTimestamp=` + data.startTimestamp + `&` + `endTimestamp=` + data.endTimestamp, options).toPromise();
}

getDoctors(){
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
     Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user')).stsTokenManager.accessToken
  });
  const options = { headers };
  return this.http.get(this.URL + `careProvider/getDoctorsByClinic`, options).toPromise();
}

removeDoctorfromClinic(id){
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
     Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user')).stsTokenManager.accessToken
  });
  const options = { headers };
  return this.http.delete(this.URL + `careProvider/doctor?physicianDocId=` + id, options).toPromise();
}

// delete careplan image

deleteCareplanimg(id): Promise<any> {
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
     Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user')).stsTokenManager.accessToken
  });
  const options = { headers };
  return this.http.delete(this.URL + `carePlans/image?carePlanId=`
   + id, options).toPromise();
}

// delete activity image

deleteCareplanactimg(pid, acid): Promise<any> {
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
     Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user')).stsTokenManager.accessToken
  });
  const options = { headers };
  return this.http.delete(this.URL + `carePlans/activities/image?carePlanId=`
   + pid + `&` + `activitieId=` + acid, options).toPromise();
}

  createPushNotification(pushObj): Promise<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Basic ODBiMzA0MjAtMjgxOS00ZDUxLWFjNDUtNWRlMGJjZDNhZDdk',
    });
    const options = { headers };
    return this.http
      .post('https://onesignal.com/api/v1/notifications', pushObj, options)
      .pipe(takeUntil(this.ngUnsubscribe))
      .toPromise();
  }

  getAllPushNotifications(): Promise<any> {
    //  const app_id = 'b41253c9-07ab-43cf-a647-e3f4e5641802';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Basic ODBiMzA0MjAtMjgxOS00ZDUxLWFjNDUtNWRlMGJjZDNhZDdk',
    });
    const options = { headers };
    return this.http
      .get(
        `https://onesignal.com/api/v1/notifications?app_id=b41253c9-07ab-43cf-a647-e3f4e5641802`,
        options
      )
      .pipe(takeUntil(this.ngUnsubscribe))
      .toPromise();
  }

  getAdminaccess(token): Promise<any> {
    //  const app_id = 'b41253c9-07ab-43cf-a647-e3f4e5641802';
    const headers = new HttpHeaders({
      // 'Content-Type': 'application/json',
      // 'Access-Control-Allow-Origin': '*',
      Authorization: 'Bearer ' + token
    });
    const options = { headers };
    return this.http.get(this.URL + `user/sa/admin`, options).toPromise();
  }

  getNextPages(): Promise<any> {
    // const app_id = 'b41253c9-07ab-43cf-a647-e3f4e5641802';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Basic ODBiMzA0MjAtMjgxOS00ZDUxLWFjNDUtNWRlMGJjZDNhZDdk',
    });
    const options = { headers };
    return this.http
      .get(
        `https://onesignal.com/api/v1/notifications?app_id=b41253c9-07ab-43cf-a647-e3f4e5641802&limit=:limit&offset=1`,
        options
      )
      .pipe(takeUntil(this.ngUnsubscribe))
      .toPromise();
  }
  // fetchData(): Promise<any> {
  //   // const headers = new HttpHeaders({
  //   //   'Content-Type': 'application/json',
  //   //    Authorization: 'Basic '+ this.idToken });
  //   // var headers = {'authorization': this.idToken }
  //   // const options = {headers};
  //   // const projectID = 'hipai-mobile-test';
  //   // const key = 'AIzaSyA6n3cEc0_7pO_nvNdLh9NIk6t_SqkFmhE';
  //   // const doc = 'physicians-base';

  // }

  public ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  checkRole(email) {
    console.log(email);
    // return this.db
    //   .collection('users', (ref) => ref.where('email', '==', email))
    //   .valueChanges();
    return this.db.collection('users').valueChanges();
  }

  addPlan(data, id) {
    // return this.db.collection('care-plans').doc(id).set(data);
    return this.db.collection('added-care-plans').doc(id).set(data);
  }

  addPlanupload(data, id) {
    // return this.db.collection('care-plans').doc(id).set(data);
    return this.db.collection('categories').doc(id).set(data);
  }
  getcatgries() {
    // return this.db.collection('care-plans').valueChanges();
    return this.db.collection('categories').valueChanges();
  }
  getPlans() {
    // return this.db.collection('care-plans').valueChanges();
    return this.db
      .collection('added-care-plans', (ref) =>
        ref.where('uid', '==', sessionStorage.getItem('uid'))
      )
      .valueChanges();
  }

  getProviders() {
    return this.db
      .collection('invite-careproviders', (ref) =>
        ref.where('uid', '==', sessionStorage.getItem('uid'))
      )
      .valueChanges();
  }


  getpatientDetails() {
    return this.db
      .collection('users', (ref) =>
        ref
          .where('claims.admin', '==', false)
          .where('claims.patient', '==', true)
          .where('claims.super_admin', '==', false)
          .where('claims.clinic', '==', false)
      )
      .valueChanges();
  }
  updatePlan(data, id) {
    return this.db.doc('care-plans/' + id).update(data);
  }

  updatecarePlan(data, id) {
    return this.db.doc('added-care-plans/' + id).update(data);
  }

  invitePatients(data) {
    return this.db.collection('invite-patients').doc(data.docId).set(data);
  }

  inviteCareProviders(data) {
    return this.db.collection('invite-careproviders').doc(data.docId).set(data);
  }

  createCareproviderUser(data) {
    return new Promise<any>((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(data.email, data.password)
        .then(
          (res) => {
            const user = res.user;
            this.useruid = res.user.uid;
            sessionStorage.setItem('careproviderdetails', this.useruid);
            resolve(res);
            this.newvalue = 'uid';
            this.uid = user.uid;
            data[this.newvalue] = this.uid;
            console.log(data);
          },
          (err) => reject(err)
        );
    });
  }

  checkUser(data) {
    return new Promise<any>((resolve, reject) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(data.Email, data.Password)
        .then(
          (res) => {
            this.useruid = res.user.uid;
            console.log(res);
            // this.isLoggedIn = 'true';
            // sessionStorage.setItem('isLoggedIn', this.isLoggedIn);
            // sessionStorage.setItem('claimRoleType', 'clinic');
            // sessionStorage.setItem('useruid', this.useruid);
            const user = res.user;
            resolve(res);
          },
          (err) => reject(err)
        );
    });
  }
  updateUserCollection(data, id) {
    return this.db.doc('users/' + id).update(data);
  }
  setUid(name) {
    this.userId = name;
  }
  getUid() {
    return this.userId;
  }

  setPatient(item) {
    this.patientObj = item;
  }

  getPatient() {
    return this.patientObj;
  }
  getinvitedPatients() {
    return this.db
      .collection('invite-patients', (ref) =>
        ref.where('uid', '==', sessionStorage.getItem('uid'))
      )
      .valueChanges();
  }

  deleteinvitedPatient(id) {
    return this.db.collection('invite-patients').doc(id).delete();
  }
  checkAssignedplans(id) {
    return this.db
      .collection('patient-plans', (ref) =>
        ref
          .where('uid', '==', sessionStorage.getItem('uid'))
          .where('patient_id', '==', id)
      )
      .valueChanges();
  }
  addpatientPlan(data) {
    return this.db.collection('patient-plans').doc(data.docId).set(data);
  }
  updatepatientPlan(data) {
    return this.db
      .collection('patient-plans')
      .doc(data.docId)
      .update({ assigned_plans: data.assigned_plans });
  }
  patientPlandetails(id) {
    return this.db
      .collection('patient-plans', (ref) =>
        ref
          .where('uid', '==', sessionStorage.getItem('uid'))
          .where('patient_id', '==', id)
      )
      .valueChanges();
  }

  getPatients() {
    return this.db
      .collection('patient-plans', (ref) =>
        ref.where('uid', '==', sessionStorage.getItem('uid'))
      )
      .valueChanges();
  }
  getPlandetails(id) {
    return this.db
      .collection('added-care-plans', (ref) =>
        ref
          .where('docId', '==', id)
          .where('uid', '==', sessionStorage.getItem('uid'))
      )
      .valueChanges();
  }
  getUserdetails(email) {
    return this.db
      .collection('users', (ref) => ref.where('email', '==', email))
      .valueChanges();
  }
  getCareProviderdetails(email) {
    return this.db
      .collection('invite-careproviders', (ref) => ref.where('email', '==', email))
      .valueChanges();
  }
  getusers(email) {
    return this.db
      .collection('users', (ref) => ref.where('email', '==', email))
      .valueChanges();
  }
  getpatientRecord(id) {
    return this.db
      .collection('invite-patients', (ref) => ref.where('docId', '==', id))
      .valueChanges();
  }
  updateStatus(id) {
    return this.db
      .collection('invite-patients')
      .doc(id)
      .update({ status: 'Accept' });
  }
  getInvitedpatientsBystatus() {
    return this.db
      .collection('invite-patients', (ref) =>
        ref
          .where('uid', '==', sessionStorage.getItem('uid'))
          .where('status', '==', 'Accept')
      )
      .valueChanges();
  }
  getUserdetailsByid(id) {
    return this.db
      .collection('users', (ref) => ref.where('guid', '==', id))
      .valueChanges({ idField: 'docId' });
  }
  setProfiledata(data) {
    this.userInf = '';
    this.userInf = data;
  }
  getprofiledata() {
    return this.userInf;
  }
  updateUser(id, data) {
    return this.db.collection('users').doc(id).update(data);
  }

  // Set appointment by CP
 setAppointment(pushObj): Promise<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
       Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user')).stsTokenManager.accessToken
    });
    const options = { headers };
    return this.http
      .post(this.URL + `appointment`, pushObj, options)
      .pipe(takeUntil(this.ngUnsubscribe))
      .toPromise();
  }

}
