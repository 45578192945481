import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'patientFilter'
})
export class PatientFilterPipe implements PipeTransform {

  transform(items: any[], searchText: string): any[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLowerCase().charAt(0).toUpperCase() + searchText.slice(1);
    // this.nameCapital = name.toLowerCase();
    // this.nameCapital = this.nameCapital.charAt(0).toUpperCase() + this.nameCapital.slice(1);
    if (searchText.length > 0) {
      return items.filter( it => {
        return it.patientName.includes(searchText);
      });
    }
   }

}
