import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalComponent } from './modal.component';
import { SmallModalComponent } from './small-modal/small-modal.component';
import { CenterModalComponent } from './center-modal/center-modal.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { TopheaderComponent } from './topheader/topheader.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InternationalPhoneNumber2Module } from 'ngx-international-phone-number2';
import { CapitalizeDataPipe } from './pipes/capitalize-data.pipe';
import { DuplicateDataPipe } from './pipes/duplicate-data.pipe';
import { FilterDataPipe } from './pipes/filter-data.pipe';
import { FormatDatePipe } from './pipes/format-date.pipe';
import { NewDateFormatPipe } from './pipes/new-date-format.pipe';
import { PatientFilterPipe } from './pipes/patient-filter.pipe';
import { SideDateFormatPipe } from './pipes/side-date-format.pipe';
import { TruncateTextPipe } from './pipes/truncate-text.pipe';



@NgModule({
  declarations: [
    ModalComponent,
    SmallModalComponent,
    CenterModalComponent,
    SidebarComponent,
    TopheaderComponent,
    CapitalizeDataPipe,
    DuplicateDataPipe,
    FilterDataPipe,
    FormatDatePipe,
    NewDateFormatPipe,
    PatientFilterPipe,
    SideDateFormatPipe,
    TruncateTextPipe
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    InternationalPhoneNumber2Module
  ],
  exports: [
    ModalComponent,
    SmallModalComponent,
    CenterModalComponent,
    SidebarComponent,
    TopheaderComponent,
    CapitalizeDataPipe,
    DuplicateDataPipe,
    FilterDataPipe,
    FormatDatePipe,
    NewDateFormatPipe,
    PatientFilterPipe,
    SideDateFormatPipe,
    TruncateTextPipe
  ]
})
export class SharedComponentsModule { }
